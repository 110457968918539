.new-matterport-icon {
	width: 30px;
	height: 30px;
}

.new-matterport-modal label {
	padding-left: 25px;
}

.new-matterport-card {
	width: 500px;
}
