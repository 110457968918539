.iguide-wrapper {
	margin-top: 20px;
	height: 84% !important;
	border-radius: 15px;
	overflow: hidden;
}

.virtual-reset {
	margin-top: 10px;
	z-index: 5;
	position: relative;
	margin-left: 10px;
}

@media (max-width: 450px) {
	.iguide-wrapper {
		height: 40vh !important;
	}
}
