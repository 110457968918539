.main {
	background-color: #eeeeee;
	display: flex;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}
body {
	height: 100%;
	overflow-y: hidden;
}

.CardHeader,
.cardHeader {
	font-size: 30px;
	display: flex;
	margin: auto;
	justify-content: center;
	background-color: transparent;
	border: none;
	color: #737373;
}

.logo-img1 {
	display: flex;
	margin: auto;
	padding-top: 15px;
	justify-content: center;
	height: 120px;
	background-color: transparent;
	width: auto;
}
.logo-img2 {
	display: flex;
	margin: auto;
	//padding-top: 15px;
	justify-content: center;
	height: 100px;
	background-color: transparent;
	width: auto;
}

///////////////////////////////////////////////////////////////////////
.orBar {
	justify-content: center;
	align-content: center;
	display: flex;
	flex-direction: row;
}
.orBar .before,
.orBar .after {
	background: #c8ceed;
	height: 1px;
	width: 100%;
	margin: 12px 5px 0 5px;
}

///////////////////////////////////////////////////////////////////////
//.logincard {
//    border-radius: 20px;
//    align-content: center;
//    justify-content: center;
//    width: 30%;
//    height: 60%;
//    display: block;
//    margin: 70px auto auto;
//    background-color: #ffffff;
//}
//.createcard {
//    border-radius: 20px;
//    align-content: center;
//    justify-content: center;
//    width: 30%;
//    height: 75%;
//    display: block;
//    margin: 70px auto auto;
//    background-color: #ffffff;
//}
.logButton {
	border-radius: 20px;
	width: 40%;
	height: 50%;
	margin: 15px auto;
	display: block;
	background-color: rgb(0, 128, 0);
}
.createButton,
.createButton:hover,
.gobackButton,
.gobackButton:hover {
	border-radius: 20px;
	height: 50%;
	margin: 15px auto;
	display: block;
	background-color: transparent;
	color: #2a5788;
	border: none;
}
//.lowerButtons{
//    padding-top: 50px;
//}

///////////////////////////////////////////////////////////////////////
.emailContainer,
.passwordContainer,
.inputContainer,
.nameContainer,
.organizationContainer {
	border-radius: 10px;
	display: flex;
	margin: 25px auto;
	width: 80%;
	height: 40px;
	flex: 1;
	flex-direction: row;
	background-color: #f1f1f1;
}
.email-icon,
.password-icon {
	flex: 1;
	font-size: 20px;
	margin: auto;
	padding-bottom: 15px;
	padding-left: 5px;
}
.passtog-icon,
.passtog-icon:hover,
.passtog-icon:focus {
	background-color: transparent;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
	overflow: hidden;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	background-image: none;
	flex: 1;
	font-size: 20px;
	margin: auto;
	padding-bottom: 15px;
	padding-left: 0px;
	padding-right: 25px;
	color: #9a9a9a;
}
.email-input,
.password-input,
.name-input,
.organization-input {
	border: none;
	font-size: 15px;
	padding-left: 10px;
	background-color: transparent;
	background-repeat: no-repeat;
	overflow: hidden;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	background-image: none;
}
.email-input:hover,
.password-input:hover,
.email-input:focus,
.password-input:focus,
.name-input:focus,
.name-input:hover,
.organization-input:focus,
.organization-input:hover {
	font-size: 15px;
	padding-left: 10px;
	border-radius: 10px;
	background-color: transparent;
	background-repeat: no-repeat;
	overflow: hidden;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	background-image: none;
}
///////////////////////////////////////////////////////////////////////

@media (min-width: 901px) {
	.logincard {
		border-radius: 20px;
		align-content: center;
		justify-content: center;
		width: 30%;
		height: 610px;
		display: block;
		margin: 40px auto auto;
		background-color: #ffffff;
	}
	.createcard {
		border-radius: 20px;
		align-content: center;
		justify-content: center;
		width: 30%;
		height: 75%;
		display: block;
		margin: 70px auto auto;
		background-color: #ffffff;
	}
	.lowerButtons {
		padding-top: 15px;
	}
}

@media (max-width: 900px) {
	.logincard {
		border-radius: 20px;
		align-content: center;
		justify-content: center;
		width: 85%;
		// height: 600px;
		display: block;
		margin: 20px auto auto;
		background-color: #ffffff;
	}
	.createButton,
	.createButton:hover {
		border-radius: 20px;
		// width: 50%;
		// height: 50%;
		margin: 15px auto;
		display: block;
		background-color: transparent;
		color: #2a5788;
		border: none;
	}
	.lowerButtons {
		padding-top: 5px;
	}
	.createcard {
		border-radius: 20px;
		align-content: center;
		justify-content: center;
		width: 60%;
		height: 75%;
		display: block;
		margin: 70px auto auto;
		background-color: #ffffff;
	}
}

.login-motto {
	color: gray;
	font-style: italic;
}
