@import './AssetTypesMasterList/AssetTypesMasterList.scss';
@import './AssetTypesOrgList/AssetTypesOrgList.scss';
@import './MasterAssetsAllList/MasterAssetsAllList.scss';
@import './MasterAssetsOrgList/MasterAssetsOrgList.scss';

.pred-spend-parent {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.pred-spend-menu {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	flex-wrap: wrap;
}
.pred-spend-menu > div {
	width: auto;
	white-space: nowrap;
	text-align: center;
}
.pred-spend-menu > select {
	max-width: 200px;
	margin: 0 10px;
	text-align: center;
}
