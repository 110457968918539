.pmreport-table-row {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
}
.toggle-label-admin {
  margin-top: 4px;
}

.current-month-health-wrapper {
  height: 350px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.current-month-single-health-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
}

.mb-6 {
  margin-bottom: 66px;
}

.previous-audit-table {
  width: 50%;
  max-height: calc(100vh - 275px);

  * {
    font-size: 15px;
  }

  th {
    font-weight: bold;
  }

  .table-title {
    font-weight: bold;
  }
}

.current-month-progress-table {
  width: 50%;
  max-height: 350px !important;

  * {
    font-size: 15px;
  }

  th {
    font-weight: bold;
  }

  .table-title {
    font-weight: bold;
  }
}

.z-100 {
  z-index: 1000 !important;
}

.ml-auto {
  margin-left: auto;
}

.week-dropdown {
  display: none !important;
}

@media (max-width: 1400px) {
  .week-dropdown {
    display: block !important;
  }
}
@media (max-width: 1100px) {
  .navbar-animated {
    .nav-link {
      font-size: 14px;
      padding: 8px 12px;
    }
  }
}

@media (max-width: 992px) {
  .previous-audit-table {
    width: 100%;
    max-height: 500px;
    min-height: 400px;
  }
}

@media (max-width: 768px) {
  .current-month-progress-table {
    width: 100%;
    max-height: 500px;
  }
  .navbar-animated {
    .nav-link {
      padding: 8px 18px;
    }
  }
}
@media (max-width: 500px) {
  .navbar-animated {
    .nav-link {
      padding: 8px 14px;
    }
  }
}
@media (max-width: 400px) {
  .previous-audit-table,
  .current-month-progress-table {
    * {
      font-size: 14px;
    }
  }
  .navbar-animated {
    .nav-link {
      padding: 8px 12px;
    }
  }
}
