.toast-show {
	opacity: 1;
	transition: all 200ms ease;
}

.toast-unshow {
	opacity: 0;
	transition: all 200ms ease;
}

.toast-wrapper {
	display: flex;
	min-width: 360px;
	padding: 12px;
	border-radius: 10px;
	position: fixed;
	margin-bottom: 36px;
}

.danger {
	border-left: 4px solid rgb(255, 69, 69);
	background-color: rgba(255, 181, 181, 0.939);

	.toast-close {
		color: rgb(255, 69, 69);
	}

	.toast-icon {
		background-color: rgb(255, 69, 69);
	}
}

.success {
	border-left: 4px solid rgb(0, 214, 93);
	background-color: rgba(130, 255, 180, 0.939);

	.toast-close {
		color: rgb(0, 255, 110);
	}

	.toast-icon {
		background-color: rgb(0, 214, 93);
	}
}

.toast-icon {
	color: white;
	font-size: 40px;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.toast-title,
.toast-subtitle {
	font-family: roboto, sans-serif !important;
	letter-spacing: 0.01rem;
	font-size: 15px;
	margin: 0;
	padding: 0;
	white-space: nowrap;
}

.toast-title {
	font-weight: 600;
	padding: 15px 0 6px 0;
}

.toast-subtitle {
	font-weight: normal;
	margin-top: 4px;
	padding: 6px 0 15px 0;
}

.toast-text-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 12px;
	padding: 6px 18px 6px 0;
}

.toast-close {
	position: absolute;
	top: 8px;
	right: 12px;
	font-size: 20px;
	cursor: pointer;
	transition: all 100ms ease;

	&:hover {
		transform: scale(1.1);
	}
}

// positions

.center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.bottom-right {
	bottom: 20px;
	right: 20px;
}

.bottom-left {
	bottom: 20px;
	left: 20px;
}

.bottom {
	bottom: 0px;
	left: 50%;
	transform: translate(-50%, 0);
}

.top {
	top: 20px;
	left: 50%;
	transform: translate(-50%, 0);
}

.toast-wrapper.bottom {
	padding: 0px;
}
