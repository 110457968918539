.chatbot-wrapper {
	position: fixed !important;
	bottom: 10px;
	right: 10px;
	z-index: 1000;
}

.react-chatbot-kit-chat-container {
	width: 300px !important;
}

.react-chatbot-kit-chat-message-container {
	overflow-x: hidden !important;
	overflow-y: scroll !important;
	scrollbar-width: thin;
	scrollbar-color: #250245 #d1d1d1;

	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: #d1d1d1;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #d1d1d1;
		border-radius: 6px;
		border: 3px solid #250245;
	}
}

.toggle-chatbot {
	width: 30px;
	height: 50px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	background-color: #250245;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 7px 3px #a7a7a7;
}

.chatbot-close {
	position: absolute;
	top: 9px;
	right: 5px;
	background-color: transparent;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	border: none;
	z-index: 100;
}

.toggle-chatbot-icon {
	color: white;
	font-size: 15px;
	width: 40px;
}

.react-chatbot-kit-chat-inner-container {
	box-shadow: 0px 8px 16px -9px grey;
	border-radius: 10px !important;

	.dark-mode & {
		background-color: $default-body-bg-dark;
		border: none;
		box-shadow: 0px 0px 1px 1px grey;
	}
}
@media (max-width: 768px) {
	.react-chatbot-kit-chat-inner-container {
		padding-bottom: 45px;
	}
}

.react-chatbot-kit-chat-header {
	color: white !important;
}

.react-chatbot-kit-chat-input {
	&:focus {
		outline: none;
	}
	&::placeholder {
		color: #b3b3b3;
	}
	.dark-mode & {
		background: #3b3b3b;
		border-color: #3b3b3b;
		color: #fff;
	}
}

.react-chatbot-kit-chat-input-form {
	width: 100%;
	display: flex;
	border-radius: 0 0 10px 10px;
	overflow: hidden;
}

.react-chatbot-kit-chat-bot-message {
	color: #0c0c0c !important;
	max-width: 185px !important;
	padding: 10px 14px !important;
	margin-right: 16px !important;
	background-color: rgb(242, 242, 242) !important;

	.dark-mode & {
		color: #e0deda !important;
		background-color: #1f2223 !important;
	}
}
@media (max-width: 768px) {
	.react-chatbot-kit-chat-bot-message {
		width: 100% !important;
	}
}

.react-chatbot-kit-user-chat-message {
	background-color: #250245 !important;
	color: white !important;
	max-width: 183px !important;
	margin-left: 16px !important;
}

.react-chatbot-kit-user-chat-message-arrow {
	border-top: 8px solid transparent !important;
	border-bottom: 8px solid transparent !important;
	border-left: 8px solid #250245 !important;
}

.react-chatbot-kit-chat-message-container {
	overflow: auto !important;
	padding-bottom: 10px;
	height: 100%;
	max-height: 413px;
	&::-webkit-scrollbar {
		width: 12px;
		height: 12px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #250245;
		border-radius: 6px;
		border: 3px solid #f1f1f1;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: rgb(73, 43, 129);
	}
}

.chatbot-header {
	background-color: #250245;
	padding: 8px;
	color: white;
	text-align: center;
	border-radius: 10px 10px 0 0;
	overflow: hidden;
}

@media (max-width: 768px) {
	.react-chatbot-kit-chat-message-container {
		height: 379px !important;
		padding-bottom: 0 !important;
	}
}
@media (max-width: 500px) {
	.chatbot-wrapper {
		height: 100vh;
		width: 100vw;
		bottom: 0;
		right: 0;
		top: 0;
		left: 0;

		& > div {
			width: 100vw;
			height: 100vh;
		}
		& > div > div {
			width: 100vw;
			height: 100vh;
		}
		.react-chatbot-kit-chat-container,
		.react-chatbot-kit-chat-inner-container {
			width: 100% !important;
			height: 100% !important;
		}
		.react-chatbot-kit-chat-message-container {
			max-height: unset;
			height: calc(100% - 40px) !important;
		}
		.chatbot-header,
		.react-chatbot-kit-chat-input-form,
		.react-chatbot-kit-chat-btn-send {
			border-radius: 0 !important;
		}
		.react-chatbot-kit-chat-bot-message {
			margin-left: 20px !important;
		}
	}
}

.loader {
	:not(.dark-mode) & {
		border-right: 0.15em solid black; /* The typwriter cursor */
	}
	.dark-mode & {
		border-right: 0.15em solid white; /* The typwriter cursor */
	}

	:not(.dark-mode) & {
		animation: blink-caret-black 0.75s step-end infinite;
		/* The typewriter cursor effect */
		@keyframes blink-caret-black {
			from,
			to {
				border-color: transparent;
			}
			50% {
				border-color: black;
			}
		}
	}
	.dark-mode & {
		animation: blink-caret-white 0.75s step-end infinite;
		/* The typewriter cursor effect */
		@keyframes blink-caret-white {
			from,
			to {
				border-color: transparent;
			}
			50% {
				border-color: white;
			}
		}
	}
}
