@media (min-width: 768px) {
	.abs-center-x {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
}

.master-list-table {
	table-layout: fixed;
	word-wrap: break-word;
	table-layout: fixed;
}

.master-list-table-card {
	border-radius: 12px;
	max-height: 75vh;
	width: 100%;

	th {
		font-weight: bold;
	}
	td,
	th {
		font-size: 15px;
	}
	tr {
		cursor: pointer;
	}
	.title {
		font-weight: bold;
	}
	scrollbar-width: thin;
	scrollbar-color: #4a4a4a #ddd;

	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: #ddd;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ddd;
		border-radius: 6px;
		border: 3px solid #4a4a4a;
		border-bottom-width: 100px;
	}
	.dark-mode & {
		scrollbar-width: thin;
		scrollbar-color: #4a4a4a #ddd;

		&::-webkit-scrollbar-track {
			background: #ddd;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #ddd;
			border-color: #4a4a4a;
		}
	}
}

.master-list-table-card.no-border {
	border-width: 0px;
}

.master-list-table-card > .card-header {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.master-list-table-card-body {
	overflow: auto;
}
