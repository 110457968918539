.predictive-spend-tab {
	td {
		vertical-align: middle !important;
		border: none;
	}
	button {
		border: none;
	}
	tr {
		cursor: pointer;
	}
	tr:hover {
		background-color: rgb(219, 219, 219);
	}
	.table-responsive {
		overflow: auto;
		height: 100%;
	}
	.card-header {
		border: none;
	}
	.card-body {
		overflow: auto;
		height: 100%;
	}
}

.predictive-spend-card {
	border: none;
	border-radius: 20px;
	box-shadow: 0px 8px 16px -9px grey;
	height: 348px;
}

.predictive-spend-row2-height {
	height: 300px !important;
}

.predictive-table-card {
	border: none;
	border-radius: $border-radius-extreme;
	box-shadow: 0px 8px 16px -9px grey;
	height: 400px;
	overflow-y: hidden !important;

	th {
		span {
			font-weight: bold;
		}
	}
	td {
		span {
			max-width: 100px;
			display: inline-block;
		}
	}

	.progress-bar {
		background-color: #228b22;
		min-width: 30px;
		position: relative;
	}
}

.predictive-btn,
.profile-filter-btn {
	width: 40px;
	height: 40px;
	color: gray;
	background-color: transparent;
	border-radius: 20px;
	font-size: 20px;
	border: none;

	&:hover {
		background-color: rgb(239, 239, 239);
	}
}

.predictive-table-row {
	color: rgb(92, 92, 92);
	border-top: none;
	background-color: transparent !important;
	font-size: 14px;
	cursor: default !important;
}

.asset-img-wrapper {
	width: 52px;
	height: 52px;
	border-radius: 10px;
	overflow: hidden;
	padding: 8px !important;
}

.border-red {
	border: 2px solid red;
}
.border-green {
	border: 2px solid red;
}

.assetHealth {
	height: 8px !important;
	border: 2px solid #747474;
	max-width: 200px;
	margin: auto;
}

.predictive-table {
	table-layout: fixed;
	overflow: auto;
	height: 100%;
}

.predictive-table > tbody {
	overflow-y: visible;
}
