.form-inputs {
  width: 45%;
}

.submit-btn {
  display: block;
  width: 25%;
  font-weight: bold;
  border: none;
}

.some-drop-shadow {
  border-radius: $border-radius-extreme;
  box-shadow: 0px 8px 16px -9px grey;
  margin-top: 10px;
  .dark-mode & {
    background-color: $default-body-bg-dark;
  }
}

.button-container {
  margin: 10px;
}

.forms-card {
  margin-top: 10px;
  border-radius: $border-radius-extreme;

  .dark-mode & {
    background-color: $default-body-bg-dark;
    border-color: grey;
    color: #ddd;

    h4, td, th, button {
      color: #ddd;
      border-color: grey;
    }
  }
}

.center-align {
  vertical-align: middle;
  display: table-cell;
}

#add-report-button {
  background-color: #6c757d;
}
#add-report-button:hover {
  background-color: #212529;
}

.form-logo-avatar {
  margin: 0 5px 0 5px;
  .avatar {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border: 1px solid #848484;
    box-shadow: 0px 8px 16px -9px grey;
    border-radius: 50%;
  }
}

.align-borders {
  transform: translateY(1px);
}

@media (max-width: 450px) {
  #add-report-button {
    font-size: 13px;
    margin-left: 0 !important;
	min-width: 84px;
  }
}

