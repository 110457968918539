.extra-margin {
  margin: 10px;
}

tr.centerText {
  text-align: center;
}

.centerText * {
  text-align: center;
}

.assets-tab .card {
  background-color: white;
  border-radius: $border-radius-extreme;
}

.date-column {
  width: 135px;
  min-width: 135px;
}

td.date-column {
  padding: 0.5rem 0;
}

.asset-row.not-being-edited > * {
  padding: 1rem;
  line-height: 2rem;
}

.asset-row.being-edited > * {
  padding: 0.75rem;
  line-height: 1rem;
}
.asset-row.being-edited > * > * {
  padding: 0.25rem;
  line-height: 2rem;
}

.asset-row .date-column {
  padding-left: 0;
  padding-right: 0;
}

.asset-row.being-edited > * > .form-range {
  padding-top: 1.5rem;
}

.assets-row-button {
  height: 36px;
  width: 36px;
  padding: 0;
}

.assets-row-button-icon {
  height: 18px;
  width: 18px;
}

.new-file-preview {
  max-height: 200px;
  max-width: 200px;
}

div.new-file-preview {
  height: 200px;
  width: 200px;
  background-color: lightgray;
}

.new-file-input {
  visibility: hidden;
  position: absolute;
}

.new-file-icon {
  height: 100px;
  width: 100px;
}

.asset-modal-tabs > * {
  transition: all 0.1s ease-in;
  padding: 12px;
  margin-bottom: 6px;
  border-radius: 12px;
}

.asset-modal-tabs > *:hover {
  cursor: pointer;
  background-color: rgb(215, 215, 215);
}

.tab-selected {
  background-color: rgb(235, 235, 235);
}

.new-asset-modal .modal-content,
.edit-asset-modal .modal-content {
  height: 700px;
}

.new-asset-modal .modal-body,
.edit-asset-modal .modal-body {
  overflow-y: auto;
}

.warranty-icon {
  width: 18px;
  height: 18px;
  color: rgb(103, 103, 103);
}

.tooltip-inactive {
  opacity: 0;
  z-index: -1;
}

.tooltip-active {
  opacity: 1;
  z-index: 100;
}

.tooltip-active,
.tooltip-inactive {
  position: absolute;
  border-radius: 10px;
  bottom: 8px;
  width: 78px;
  padding: 8px;
  background-color: rgb(103, 103, 103);
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 11px;
  color: white;
  transition: all 150ms ease-in-out;
}

.tooltip-wrapper {
  position: relative;
}

.table-responsive {
  overflow: visible;
}