.partner-outer {
  width: 90px;
  height: 90px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  margin-bottom: 40px;
}

.partner-inner {
  position: absolute;
  border: 8px solid #d6d6d6;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.scale-120 {
  transform: scale(1.2);
}

.shadow-green {
  box-shadow: 0px 0px 20px 0px #228b22 !important;
  -webkit-box-shadow: 0px 0px 20px 0px #228b22 !important;
  -moz-box-shadow: 0px 0px 20px 0px #228b22 !important;
}

.border-green {
  border-color: #228b22 !important;
}
