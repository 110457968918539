.navbar {
  display: flex;
  //height: 500px;
  padding-top: 0px;
  //padding-right: 50px;
  padding-right: 0;
  background-color: $default-body-bg;
  position: relative;
  height: 75px;
  margin-bottom: 15px;
}

.navbar-brand {
  text-transform: capitalize;
  font-size: 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.625rem;
  margin-left: 250px;
}

.search-icon-btn {
  max-width: 30px !important;
  max-height: 30px !important;
  transform: translateX(-5px);
}

.title {
  margin-left: 40px;
}

.navbar-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-padding {
  padding: 0;
}
@media (max-width: 767px) {
  .title {
    margin-left: 0;
  }
  .nav-menu {
    background: transparent;
    display: block;
    .navbar-normal > .active > .triangle-left {
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-right: 20px solid #f4f3ef;
      border-bottom: 13px solid transparent;
      position: absolute;
      top: 10px;
      right: -15px;
    }
    .navbar-normal {
      margin: 0;
      position: relative;
      transform: translateX(0px);
      opacity: 1;
      white-space: nowrap;
      display: block;
      line-height: 20px;
      z-index: 1;

      a {
        margin: 10px 15px 0;
        color: $white-color;
        display: block;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 12px;
        padding: 10px 8px;
        line-height: 30px;
      }

      .active {
        background-color: transparent;
        color: $primary-color !important;
        .navbar-mini-icon {
          color: $primary-color !important;
          text-transform: uppercase;
          width: 34px;
          margin-right: 10px;
          margin-left: 0px;
          font-size: 24px;
          text-align: center;
          line-height: 20px;
          position: relative;
          float: left;
          z-index: 1;
          display: inherit;
        }
      }

      .inactive {
        background-color: transparent;
        color: $opacity-1;

        .dark-mode & {
          color: #ddd;
        }
        .navbar-mini-icon {
          text-transform: uppercase;
          width: 34px;
          margin-right: 10px;
          margin-left: 0px;
          font-size: 24px;
          text-align: center;
          line-height: 20px;
          position: relative;
          float: left;
          z-index: 1;
          display: inherit;
          color: $opacity-1;

          .dark-mode & {
            color: #ddd;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .nav-menu {
    display: none;
  }
}
.no-attributes {
  color: #cccccc;
}
.no-attributes:hover {
  color: #939393;
}

/////////////////////////////////////////////////////

///////////////////////////////////////////////////// Navbar right buttons

.navbar-right button:hover {
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-image: none;
}

.navbar-right button {
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  color: #cccccc;
  background-image: none;
  padding: 3px;
}

.toggle-alfred {
  color: white !important;
  background-color: #aeaeae;
  padding: 7px 14px !important;
  margin-left: 12px;
  border-radius: 30px;
  font-weight: bold;

  &:hover {
    opacity: 0.85;
  }
}

///////////////////////////////////////////////////// Dropdown
.show > .btn-secondary.dropdown-toggle {
  // background-color: transparent;
  // border: none;
  background-repeat: no-repeat;
  cursor: pointer;
  //overflow: hidden;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-image: none;
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.Dropdown:hover {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  //overflow: hidden;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-image: none;
}

///////////////////////////////////////////////////// icons

.navbar-mini-icon {
  padding: 5px;
  font-size: 30px;
  color: #cccccc;
}

@media (max-width: 1025px) {
  .title {
    width: 300px;
  }
  .navbar-mini-icon {
    padding: 0px 2px;
    font-size: 30px;
    height: 24px;
    width: 24px;
  }

  .no-attributes {
    height: 20px;
    width: 20px;
  }
}

.dashboard-motto {
  margin-left: 50px;
  color: #a6a0ae;
}

.navbar-motto,
.motto {
  color: #a6a0ae;
  font-style: italic;
  background-color: #fff;
  .dark-mode & {
    color: #d2cec8;
    background-color: $default-body-bg-dark;
  }
}

@media (min-width: 769px) {
  .navbar-motto {
    height: 0px;
    visibility: hidden;
    position: relative;
  }
}

@media (min-width: 1152px) {
  .motto {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: italic;
    margin-left: 5px;
  }
  .navbar-motto {
    display: none;
  }
}

@media (max-width: 769px) {
  .navbar-wrapper {
    margin-bottom: 20px !important;
  }
  .navbar-motto {
    margin-bottom: 0 !important;
  }
  .motto {
    display: inline-block;
  }
  .navbar-motto {
    text-align: end;
    padding-right: 8px;
  }
  .search-navbar {
    margin-right: 6px;
    margin-top: 8px;
  }
  .navbar-right {
    margin-left: auto;
    margin-top: 10px;
  }
}

@media (max-width: 1151px) {
  .logo-anchor {
    text-decoration: none;
  }
  .navbar-motto {
    font-style: italic;
    margin-left: 2.8rem;
    text-decoration: none;
    display: block;
  }
  .motto {
    display: none;
  }
}

// // // Filter Styling // // //

$cubic-out: cubic-bezier(0.32, 2.04, 0.85, 0.54);
$cubic-in: cubic-bezier(0.72, 1.2, 0.71, 0.72);
$nunito: 'Nunito', sans-serif;
$roboto: Roboto, sans-serif;

$cyan: #00bcd4;
$yellow: #ffeb3b;
$grey: #9e9e9e;

$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);

.alert-kebab-wrapper {
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
  top: 12px;
}

.alert-title {
  transition: all 300ms ease !important;
  border-bottom: #2125297a solid 0.5px;
}

.alert-btn {
  background-color: transparent;
  position: relative;
  padding: 6px 12px;
  width: 36px;
  height: 36px;
  margin-left: 10px;
}

.alert-close,
.alert-close-expand,
.alert-dots {
  position: absolute;
  width: 35px;
  height: 35px;
  right: 0;
  bottom: -1px;
}
.alert-dots {
  font-size: 22px !important;
}

.alert-close,
.alert-close-expand {
  color: white;
  border-radius: 50%;
  padding: 6px;
  background-color: #228b22;
}

.alert-close {
  transform: scale(0);
  transition: all 200ms ease;
}

.alert-close-expand {
  transform: scale(1);
  transition: all 200ms ease;
}

.alert-item {
  border-bottom: #2125297a solid 0.5px;
  font-weight: lighter;
  font-size: 14px;
}

.alert-item:hover {
  background-color: #f3f3f3;
}

.alert-kebab {
  padding: 10px;
  position: absolute;
  z-index: 3;
  transition: all 0.25s ease-out;
  transform: scale(0);
  transform-origin: 100% 0;
  box-shadow: $shadow;
  width: 320px;
  border-radius: 10px;
  color: #212529;
}

@media (min-width: 769px) {
  .alert-kebab {
    right: 0;
  }
}

@media (max-width: 768px) {
  .alert-kebab {
    right: -90px;
    margin-top: 10px;
  }
}

.alert-kebab.active {
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  background-color: white;
}

.alert-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background: rgb(255, 54, 84);
  color: white;
  padding: 0px 7.5px;
}

.send-email-text-input:focus {
  outline: none;
}
