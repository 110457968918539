tr.centerText {
	text-align: center;
}

.centerText * {
	text-align: center;
}

.docs-tab .card {
	background-color: white;
	border-radius: $border-radius-extreme;
}

.doc-row > * {
	padding: 1rem;
	line-height: 2rem;
}

.docs-row-buttons-container > button {
	height: 36px;
	width: 36px;
	padding: 0;
}

.docs-row-buttons-container > * > * {
	height: 18px;
	width: 18px;
}

.doc-link:hover {
	cursor: pointer;
	color: rgb(63, 161, 14);
}

.doc-link {
	text-decoration: underline;
	color: rgb(41, 123, 0);
}

.doc-image-pop-up {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 3;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	transition: all 3s ease-in-out;
}

.doc-image-container {
	display: flex;
}

.doc-image-x-button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	padding: 10px;
	margin: 10px;
	font-size: 16px;
	height: 30px;
	width: 30px;
	background-color: rgb(50, 50, 50);
	color: white;
	border-radius: 50%;
	border: white 1px solid;
}

.doc-image-x-button:hover {
	background-color: rgb(75, 75, 75);
	cursor: pointer;
}

.doc-image {
	max-height: 600px;
	border-radius: 5px;
	border: grey 1px solid;
}

.docs-tab-table-title {
	padding: 0 15px;
	opacity: 0.7;
}

.docs-tab .card-header {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	padding: 0;
}

.docs-row-button {
	height: 36px;
	width: 36px;
	padding: 0;
}

.docs-row-button-icon {
	height: 18px;
	width: 18px;
}

.width-fit-content {
	width: fit-content;
}
