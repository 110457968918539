.matterport-tab {
	width: 100%;
	height: 100vh;
}

.margin-top {
	margin-top: 15px;
}

.matterport-view {
	height: 100%;
	width: 100%;
	border-radius: $border-radius-extreme;
}

.mattertags-container {
	background-color: white;
	min-height: 75%;
	max-height: 75%;
	overflow: scroll;

	h4 {
		margin: 8px;
	}
}

.model-view-container {
	width: 100%;
	height: 100%;
	max-height: 730px;
	border-radius: 20px;
	overflow: hidden;
}

.mattertags-container ul {
	padding-left: 0;
}

.mattertags-container ul li.mattertag {
	padding: 8px 12px;
	list-style-type: none;
}

.mattertags-container ul li.mattertag:hover {
	background-color: #0273d4;
	border-radius: 8px;
	color: white;
	cursor: pointer;
}

.row-container-mattertags {
	max-height: 75vh;
	min-height: 63vh;
	// max-width: 100vw;
	// min-width: 100vw;
}

.toolbar-mattertags {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.filter-dropdown {
	.dropdown {
		background-color: black !important;
	}
}

.bottom-footer-mattertag {
	background-color: #ffffff;
	margin-top: 160px;
	max-width: 80.25vw;
	min-width: 80.25vw;
}

.no-overflow {
	th {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 200px;
	}
}

.color-circle {
	width: 25px;
	height: 25px;
	border-radius: 100%;
}

.assets-filter-button {
	border-radius: 50%;
	transition: all ease-in 0.1s;
	height: 35px;
}

.assets-filter-button:hover {
	background-color: #eeeeee;
}

.assets-filter-button:active {
	background-color: #dddddd;
}
