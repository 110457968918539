.cancel-btn {
    background-color: #f3f3f3 ;
    border: none ;
    color: #717171 ;
}

.confirm-btn {
    background-color: #696969;
    border: none;
    color: white;
}