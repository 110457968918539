.loading-modal .modal-content {
    background-color: transparent !important;
    border: none !important;
}

@media (max-width: 767px) {

    .loading-modal.modal-dialog {
        left: 33%;
        top: 33%;
        width: 125px;
        height: 125px;
        .loading-spinner {
            margin-top: 0;
        }
    }

}

@media (min-width: 768px) {

    .loading-modal.modal-dialog {
        left: 8%;
        top: 30%;
        width: 125px;
        height: 125px;
        .loading-spinner {
            margin-top: 0;
        }
    }

}