.image-submitted {
    max-width: 100%;
}

.submitted-image-container {
    position: relative;

    .tools {
        position: absolute;
        bottom: 3px;
        left: 5px;
        border: solid 1px rgba(255, 255, 255, 1);
        display: flex;
        gap: 20px;
        font-size: 20px;
        border-radius: 8px;

        .btn-zoom {
            border: none;
            background: transparent;
            color: #fff;
        }
    }

}

.btn-zoom-pop {
    animation: pop 0.75s;
}
@keyframes pop {
    0%,100% {
      transform: scale(1.0);
    }
    50% {
      transform: scale(1.5);
    }
  }