.cost-savings-title {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
}

.cost-savings-card {
  border-radius: $border-radius-extreme;
  box-shadow: 0px 8px 16px -9px grey;
  border: none;
}

.cost-savings-select {
  width: 200px;
}

.cost-savings-table {
  table-layout: fixed;
  th {
    font-weight: bold;
  }
  td {
    text-align: start;
  }
  th,
  td {
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.cost-savings-footer {
  flex: wrap;
}

.text-darkgrey {
  color: rgb(120, 120, 120);
}
