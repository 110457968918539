.carousel-item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 20px;
    background-color: darkgray;
    color: white;
    font-size: 32px;
  }