@import './children/AddUserModal.scss';
@import './children/EditUserModal.scss';

.users-table {
  th {
    font-weight: bold;
  }
}

.users-table-row {
  cursor: pointer;
}
.users-table-row:hover {
  background-color: #250245;
  color: white;
}

