.messageContainer {
    background-color: rgb(242, 242, 242);
    box-shadow: 0px 8px 16px -9px grey;
    border-radius: 20px;
    margin-bottom: 16px;
	max-width: 70%;

    padding-left: 16px;
	padding-right: 12px;
	padding-bottom: 6px;
	padding-top: 6px;
}

.messageText {
    color: #0c0c0c;
	font-weight: 600;
	font-size: 1rem;
    white-space: pre-wrap;
}


.messageContainer_dark {
    background-color: #1f2223;
    box-shadow: #60686c 0px 8px 16px -9px;
    border-radius: 20px;
    margin-bottom: 16px;
	max-width: 70%;

    padding-left: 16px;
	padding-right: 12px;
	padding-bottom: 6px;
	padding-top: 6px;
}

.messageText_dark {
    color: #e0deda;
	font-weight: 600;
	font-size: 1rem;
    white-space: pre-wrap;
}