.text-pe-green {
	color: #228b22;
}
.text-pe-red {
	color: #df3053;
}

.bg-green {
	background-color: #228b22;
}
.bg-purple {
	background-color: #250245;
}
.text-teal {
	color: #00ccff;
}
.bg-teal {
	background-color: #00ccff;
}
.bg-gray {
	background-color: #f5f5f5;
}

.landing {
	position: relative;

	a {
		text-decoration: none;
	}

	.landing-nav-item {
		a {
			color: #250245;
		}
	}
	section:nth-child(odd) {
		background-color: #f5f5f5;
	}
	section {
		min-height: calc(100vh - 75px);
	}
}

.landing-container {
	padding: 120px 0;
	height: 100%;
}

.landing-row {
	max-width: 1260px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
}

.landing-navbar {
	position: fixed !important;
	width: 100% !important;
	z-index: 100;
	border-bottom: 1px solid rgb(243, 243, 243);
}

.landing-logo,
.landing-nav-link,
.landing-caret {
	cursor: pointer;
}

.landing-nav-link {
	padding-right: 12px;
}

.landing-nav-row {
	max-width: 1260px;
	width: 100%;
}

.landing-logo {
	width: 300px;
	object-fit: contain;
}

.landing-caret,
.landing-caret-active {
	width: 12px;
	margin-top: 2px;
	transition: 300ms ease;
}

.landing-caret-active {
	transform: rotate(180deg);
}

.landing-navbar * {
	letter-spacing: 0.3px;
	font-size: 15px;
	font-weight: bold;
}

.landing button {
	border-radius: 40px;
	border: none;
	font-weight: bold;
	cursor: pointer;
}

.landing-navbar li {
	display: flex;
	align-items: center;
}

.landing-sub-menu {
	position: absolute;
	background-color: #fff;
	left: 0;
	top: 75px;
	width: 300px;
	border-radius: 0 0 16px 16px;
	-webkit-box-shadow: 0px 3px 15px rgb(0 0 0 / 12%);
	box-shadow: 0px 3px 15px rgb(0 0 0 / 12%);
	padding: 24px;
	opacity: 0;
	z-index: 100 !important;
	visibility: hidden;
	transition: 200ms ease;
}

.landing-nav-item {
	position: relative;
	padding: 27px 0;
}

.landing-nav-item:hover {
	.landing-nav-link {
		opacity: 0.7;
	}
	.landing-caret {
		transform: rotate(180deg);
	}
	.landing-sub-menu {
		opacity: 1;
		visibility: visible;
	}
}

.sub-menu-item > a:hover {
	color: #228b22 !important;
	cursor: pointer;
}

.landing-login {
	color: #228b22 !important;
	margin-left: 24px;
	transition: all 300ms ease;
}
.landing-login:hover {
	color: #250245 !important;
}

.landing-btn {
	min-width: 190px;
	background-color: #228b22;
	box-shadow: 0 10px 20px -10px #228b22;
	border: none;
	color: white;
	transition: 250ms ease;
}

.landing-btn:hover {
	background-color: #166a16;
}

.nav-btn {
	height: 48px;
	width: 150px;
}

.landing-modal-btn {
	position: absolute;
	top: 25px;
	right: 15px;
	background-color: transparent;
	padding: 0;
	display: none;
}

.landing-modal-active,
.landing-modal-inactive {
	position: fixed;
	width: 100vw;
	height: calc(100vh - 73px);
	padding: 24px;
	background-color: white;
	left: 0;
	top: 73px;
	right: 0;
	bottom: 0;
	z-index: 10;
	transition: all 400ms ease;
}

.landing-modal-active {
	opacity: 1;
	visibility: visible;
	transform: translateY(0px);
}

.landing-modal-inactive {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-200px);
}

.landing .accordion-button {
	background-color: transparent !important;
	&:not(.collapsed) {
		color: #250245 !important;
	}
}
.landing .accordion-button::after {
	background-image: url(../../assets/img/caret-down.png);
}

.hero-area {
	padding: 195px 32px 40px 32px;
	min-height: 100vh !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	img {
		animation: fadeInDelay2 ease 2500ms;
	}
}

.hero-img {
	width: 100%;
	height: auto;
	max-width: 520px;
}

.hero-text-wrapper {
	animation: fadeInDelay1 ease 2s;
}

.hero-title {
	font-size: 52px;
	color: #228b22;
	font-style: italic;
}

.hero-subtitle {
	max-width: 400px;
	color: rgb(140, 140, 140);
}

.hero-buttons {
	gap: 32px;
}

.landing-btn-shadow {
	box-shadow: 0 10px 20px -10px #228b22;
}

.outline-btn {
	background-color: transparent;
	border: 2px solid #5cc65c !important;
	color: #228b22;
	// height: 52px;
	min-width: 190px;
	transition: border 400ms ease;
}
.outline-btn:hover {
	border-color: #228b22 !important;
}

.brands-wrapper {
	animation: fadeInDelay3 ease 3000ms;
}

.brands-para {
	max-width: 300px;
	color: rgb(140, 140, 140);
}

.brands-img {
	width: 250px;
}

// Card section

.landing-large-card,
.landing-small-card {
	border-radius: 20px;
	position: relative;
	min-height: 260px;
	padding: 32px;
	cursor: pointer;
	transition: all 300ms ease;
	overflow: hidden;
}

.landing-large-card:hover,
.landing-small-card:hover {
	transform: scale(1.03);
	.landing-card-arrow-wrapper {
		animation: bounce 2s linear infinite;
	}
}

.landing-card-arrow-wrapper {
	width: 32px;
	height: 32px;
	background-color: #228b22;
	border-radius: 50%;
}

.landing-card-subtitle {
	max-width: 400px;
	font-size: 18px;
}

.landing-card-icon {
	position: absolute;
	bottom: 20px;
	right: 20px;
	width: 80px;
	height: 80px;
}

// carousel cards section

.carousel-card {
	overflow: hidden;
	border-radius: 20px;
	min-height: 320px;
	position: relative;
	margin-bottom: 24px;
	padding-bottom: 12px;
	max-width: 340px;
	margin-left: auto;
	margin-right: auto;
}

.carousel-wrapper {
	display: flex;
	justify-content: space-between;
	width: calc(64px * 12);
	animation: slide 10s linear infinite;
}

.carousel-side-fade {
	background: white;
	position: relative;
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;

	&::before,
	&::after {
		content: '';
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		position: absolute;
		height: 100px;
		width: 200px;
		z-index: 2;
		bottom: 0;
	}
	&::before {
		left: 0;
	}
	&::after {
		right: 0;
		transform: rotateZ(180deg);
	}
}

.landing-bar {
	margin-top: 90px;
}

.landing .progress-bar {
	background-color: #228b22;
}

// parters section

#demo-row {
	max-width: 1400px;
	width: 100%;
	margin: 0 auto;
}

.move-right {
	transform: translateX(60px);
}
.move-left {
	transform: translateX(-60px);
}

@media (max-width: 1200px) {
	.landing-card-title {
		font-size: 22px;
	}
}

@media (max-width: 980px) {
	.landing-large-card {
		margin-bottom: 24px;
		height: 240px;
	}
	.landing-nav-list,
	.landing-nav-right {
		display: none !important;
	}
	.hero-area {
		padding-top: 120px;
	}
	.hero-title {
		font-size: 44px;
	}
	.hero-subtitle {
		margin: 20px 0;
		font-size: 18px;
	}
	.hero-img {
		margin-top: 20px;
		max-width: 360px;
	}
	.mobile-none {
		display: none;
	}
	.brands-para {
		margin-top: 40px;
	}
	.landing-modal-btn {
		display: block;
	}
}
@media (max-width: 400px) {
	.landing-logo {
		width: 250px;
	}
	.landing-large-card,
	.landing-small-card {
		padding: 24px;
	}
}

@keyframes fadeInNoDelay {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeInDelay1 {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeInDelay2 {
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeInDelay3 {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes bounce {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(20px);
	}
	100% {
		transform: translateX(0px);
	}
}

@keyframes slide {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-392px);
	}
}
