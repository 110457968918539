.slideshow-wrapper {
  width: 900px;
  height: 500px;
  border: 10px solid #250245;
  border-radius: 20px;
  position: relative;
}

.slideshow-img {
  animation: fadeInNoDelay 1500ms ease-in-out;
}

.slideshow-btn-left,
.slideshow-btn-right {
  position: absolute;
  border: 2px solid black !important;
  color: black;
  background-color: transparent;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  top: 50%;
  transform: translate(0, -50%);
}

.slideshow-btn-right {
  right: 20px;
}
.slideshow-btn-left {
  left: 20px;
}
