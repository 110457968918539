.avatar-upload-input {
	height: 100%;
	width: 100%;
	opacity: 0;
	position: absolute;
	z-index: 3;
}

.avatar-upload-overlay {
	position: absolute !important;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 2;
	height: 100%;
	width: 100%;
}

.avatar-upload-overlay.active {
	opacity: 1;
}

.avatar-upload-overlay.inactive {
	opacity: 0;
}
