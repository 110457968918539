.mw-50 {
    max-width: 50%;
}

@media (min-width: 993px) {
    .first-line {
        width: 48%;
    }
}

@media (max-width: 992px) {
    .first-line {
        width: 100%;
    }
}

.form-res-parent {
    border-radius: 6px;
    padding: 12px 24px;
}

.form-res-header {
    padding-bottom: 12px;
    font-size: smaller;
}

.form-res-answer {
    font-weight: bold;
}

.activities-page-buttons>button:hover {
    background-color: rgb(220, 220, 220);
}

.activities-page-buttons>button:active {
    background-color: rgb(210, 210, 210);
}

.activities-page-header-buttons {
    cursor: pointer;
    color: black;
    transition: ease-in-out 0.1s;
    border-radius: 8px;
    text-decoration: none;
}

.activities-page-header-buttons:active,
.activities-page-header-buttons:visited {
    color: black;
    text-decoration: none;
}

.activities-page-header-buttons:hover {
    background-color: rgba(128, 128, 128, 0.125);
    color: black;
    text-decoration: none;
}

.submitted-image {
    width: 100%;
    object-fit: contain;
    border-radius: 0.25rem;
    max-width: 300px;
}

.submitted-image:hover {
    background-color: rgba(0, 0, 0, 0.25);

}

.submitted-image-parent {
    max-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .button-array {
        display: none;
    }

    .submitted-image {
        cursor: pointer;
    }
}

.submitted-image-parent:hover {
    .button-array {
        display: flex;
        flex-direction: row;

        button {
            margin: 5px;
        }
    }
}



.image-btn {
    background-color: #fdc15a;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin: 5px;
}

.submission-logo {
    width: 200px;
    margin: auto;
}

.submission-title {
    font-size: 36px;
    font-weight: bolder;
    margin: auto;
}

.submission-section-label {
    background-color: #e9ecef;
    padding: 0.375rem 0.75rem !important;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-bottom: 0;
    font-weight: bold;
}

.submission-tab {
    font-size: 24px;
    font-weight: bold;
}

.submission-label {
    padding-top: 0px !important;
}

.form-label {
    padding-top: 0px;
}

@media print {

    .sidebar,
    .toggle-button,
    .topbar,
    .single-location-parent>*:not(.tab-content),
    .tab-page:not(.active),
    .submission-header,
    .submission-title,
    .submission-logo {
        display: none;
        visibility: hidden;
        height: 0px;
        width: 0px;
        background: none;
        margin: 0px;
        padding: 0px;
    }

    .main-page-container {
        margin: 0px;
        padding: 0px;
        box-shadow: none;
    }

    .assets-tab {
        padding: 0px;
    }

    .assets-tab>* {
        padding: 0px;
    }

    #submission {
        padding: 0px;
        border: none;
        margin-top: -90px;
    }

    @page {
        margin: 50px 0px;
    }

    html {
        margin: 0px;
    }
}

#submission {
    border-radius: 12px;
}

.submission-buttons>* {
    margin: 0 6px;
}

.submission-buttons>.save {
    background-color: #228b22;
}

.submission-buttons>.reset {
    background-color: #df3053;
}