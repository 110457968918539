.blank-btn {
    color: black;
    background: white;
    border: none;
    padding: 0;
    font-size: 16px;
    padding: 10px;
}

ul {
    padding: 2px;
}
.picker-item {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 12px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 2px;
    border-radius: 10px;
    margin-top: 2px;
    border: 0.5px solid #b5b5b57d;
}

.modal-restrict {
    height: 500px;
    width: 500px;
}

