.card.large-card {
  border-radius: $border-radius-extreme;
  // box-shadow: 0px 8px 16px -9px grey;
  border: none;
}

.large-card th {
  font-weight: normal;
  font-size: small;
}

.map-bg-wrapper {
  background: url(../../../../../../assets/img/largelinebg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
  overflow: hidden;
  transition: all 300ms ease;
}

.large-card tr:hover > * {
  color: white !important;
}

#large-card-body {
  border-top: 0px;
}

.large-card:hover {
  .map-bg-wrapper {
    background-color: #250245 !important ;
  }
  .text-transition {
    color: white !important ;
    transition: all 400ms ease-in-out;
  }
}

.large-icon {
  transition: all 400ms ease-in-out;
}

@media (max-width: 991px) {
  .large-card .map {
    height: 600px;
  }
}

@media (min-width: 992px) {
  .large-card .map {
    height: 100%;
  }
}
