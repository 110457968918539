// .mb-3 {
//   margin-bottom: 0px !important;
// }

.form-check-input {
	margin: 10px;
}

.single-form-label {
	padding-top: 18px !important;
}

.form-logo {
	width: 225px;
	margin: 0 auto;
}

.form-title {
	font-size: 20px;
	font-weight: bolder;
	.dark-mode & {
		color: #ddd;
	}
}

.nav-item .active.nav-link .single-form-label {
	color: $primary-color;
	font-weight: bold;
}

.nav-item .inactive.nav-link .single-form-label {
	color: black;
	opacity: 0.5;
}

.tab-pane.active .accordion-header .accordion-button {
	color: $primary-color;
	background-color: #f3f3f3;
}

.tab-pane.active .accordion-header .accordion-button.collapsed {
	color: black !important;
	background-color: #f3f3f3;
	.dark-mode & {
		color: #ddd !important;
		background-color: #1f2123 !important;
		&::after {
			color: #ddd;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.form.nav-tabs {
	height: 55px;
	min-width: 309px;
	.dark-mode & {
		border: none;
	}
}

.form.nav-tabs a {
	padding-top: 0;
	padding-bottom: 0;
}

.form.nav-tabs .nav-link {
	position: relative;
	padding-left: 0.7em;
	padding-right: 0.7em;
}

.form.nav-tabs .nav-link:focus,
.form.nav-tabs .nav-link:hover,
.form.nav-tabs .nav-link.active {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.accordion-button {
	padding-top: 5px;
	height: 50px;
	padding-bottom: 5px;
}

@media (max-width: 767px) {
	.form-btn-row {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		.col {
			margin: auto;
			text-align: center;
			width: 50%;

			label {
				width: 100%;
			}
			.btn {
				width: 100%;
				box-shadow: 0px 8px 16px -9px grey;
				border-radius: 10px;
				background-color: grey;
				color: white;
				border: none;
			}
		}
	}
}
@media (min-width: 768px) {
	.form-btn-row {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-end;

		.col {
			text-align: center;

			label {
				width: 100%;
			}

			.btn {
				width: 100%;
				box-shadow: 0px 8px 16px -9px grey;
				border-radius: 10px;
				background-color: grey;
				color: white;
				border: none;
				white-space: nowrap;
			}
		}
	}
}
.in-form-btn {
	box-shadow: 0px 8px 16px -9px grey;
	border-radius: 10px;
	background-color: grey;
	color: white;
	border: none;
}

.form-btns-submit {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	transform: translateX(-15px);
}

.submit-btn-form {
	box-shadow: 0px 8px 16px -9px grey;
	border-radius: 10px;
	background-color: $primary-color;
	color: white;
	border: none;
	white-space: nowrap;
	margin: 15px;
}

.text-area {
	width: 100%;
}

.form-inputs {
	width: 100%;
}

// Toggle Switch

.toggle {
	position: relative;
	cursor: pointer;
	display: inline-block;
}

.toggle-switch {
	display: inline-block;
	background-color: #e6e6e6;
	box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 30%) inset;
	border-radius: 16px;
	width: 64px;
	height: 28px;
	position: relative;
	vertical-align: middle;
	transition: background 0.25s;
}

.toggle-switch:before,
.toggle-switch::after {
	content: '';
}

.toggle-switch:before {
	display: block;
	background: radial-gradient(
		circle at 50% 50%,
		rgb(222, 222, 222) 0%,
		rgba(255, 255, 255, 1) 90%
	);
	border-radius: 50%;
	box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
	width: 26px;
	height: 26px;
	position: absolute;
	top: 1px;
	left: 2px;
	transition: left 0.25s;
	z-index: 100;
}

.toggle-switch:hover .toggle-switch:before {
	background: white;
}

.toggle-checkbox:checked + .toggle-switch {
	background: $primary-color;
}

.toggle-checkbox:checked + .toggle-switch::before {
	left: 36px;
}

.toggle-checkbox {
	position: absolute;
	cursor: pointer;
	z-index: 10;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.toggle-label {
	display: inline;
	margin-left: 6px;
}

.toggle-on-text,
.toggle-off-text {
	position: absolute;
	font-size: 11px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	font-weight: bold;
	user-select: none;
}

.toggle-on-text {
	left: 9px;
	color: rgb(74, 161, 113);
}
.toggle-off-text {
	right: 6px;
	color: darkgray;
}

.dark-mode {
	.accordion {
		.accordion-item {
			background-color: $default-body-bg-dark !important;
			color: #ddd !important;
			border-color: #4a4a4a;
			.accordion-header {
				background: none !important;
				button {
					color: #ddd !important;
					background: none !important;
				}
			}
			.accordion-body {
				button {
					color: #ddd !important;
					background-color: #181a1b !important;
				}
				select,
				input {
					color: #ddd;
					background-color: $default-body-bg-dark;
				}
			}
		}
	}
}
