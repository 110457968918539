.health-card {
	border-radius: $border-radius-extreme;
	border: none;
	background: transparent;
	position: relative;
	width: 100%;
	max-width: 360px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: none;
	padding: 20px;
}

.CircularProgressbar-text {
	font-size: 20px !important;
}

.health-title {
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translate(-50%, 0);
	font-size: 14px;
	white-space: nowrap;
	transition: all 300ms ease-in-out;
}

.health-circle-wrapper {
	margin: 0 auto;
	border-radius: 50%;
	width: 100%;
	position: relative;
	// -webkit-box-shadow: 0px 0px 50px 10px rgba(151, 16, 204, 0.74);
	// -moz-box-shadow: 0px 0px 50px 10px rgba(151, 16, 204, 0.74);
	// box-shadow: 0px 0px 50px 10px rgba(151, 16, 204, 0.74);
	transition: transform 300ms ease-in-out;
}

// .outer-glow-red {
// 	-webkit-box-shadow: 0px 0px 50px 10px #df3053;
// 	-moz-box-shadow: 0px 0px 50px 10px #df3053;
// 	box-shadow: 0px 0px 50px 10px #df3053;
// }

// .outer-glow-yellow {
// 	-webkit-box-shadow: 0px 0px 50px 10px #fbc658;
// 	-moz-box-shadow: 0px 0px 50px 10px #fbc658;
// 	box-shadow: 0px 0px 50px 10px #fbc658;
// }

.health-score-purple,
.health-score-green,
.health-score-yellow,
.health-score-red,
.health-outer-glow {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.health-score-green {
	-webkit-box-shadow: 0px 0px 10px 6px rgba(15, 255, 99, 1);
	-moz-box-shadow: 0px 0px 10px 6px rgba(15, 255, 99, 1);
	box-shadow: 0px 0px 10px 6px rgba(15, 255, 99, 1);
}
.health-score-red {
	-webkit-box-shadow: 0px 0px 10px 6px #df3053;
	-moz-box-shadow: 0px 0px 10px 6px #df3053;
	box-shadow: 0px 0px 10px 6px #df3053;
}
.health-score-yellow {
	-webkit-box-shadow: 0px 0px 10px 6px #fbc658;
	-moz-box-shadow: 0px 0px 10px 6px #fbc658;
	box-shadow: 0px 0px 10px 6px #fbc658;
}
.health-score-purple {
	-webkit-box-shadow: 0px 0px 10px 6px rgb(135, 15, 255);
	-moz-box-shadow: 0px 0px 10px 6px rgb(135, 15, 255);
	box-shadow: 0px 0px 10px 6px rgb(135, 15, 255);
}

.red {
	color: #df3053 !important;
}

.yellow {
	color: #fbc658 !important;
}

.green {
	color: #228b22 !important;
}

.health-outer-glow {
	transition: all 300ms ease-in-out;
	background-color: transparent;
}

.health-circle-wrapper:hover .health-outer-glow {
	-webkit-box-shadow: 0px 0px 25px 20px rgba(151, 16, 204, 0.74);
	-moz-box-shadow: 0px 0px 25px 20px rgba(151, 16, 204, 0.74);
	box-shadow: 0px 0px 25px 20px rgba(151, 16, 204, 0.74);
}

.health-circle-wrapper:hover {
	transform: scale(1.02);
}

@media (max-width: 1200px) {
	.health-card {
		margin: 20px 0;
	}
	.health-card {
		max-width: 340px;
	}
}

@media (max-width: 768px) {
	.dashboard-motto {
		height: 0px;
		position: relative;
		visibility: hidden;
	}
	.health-card {
		margin: 0;
	}
}

@keyframes growBounce {
	0% {
		transform: scale(0);
	}
	80% {
		transform: scale(1.07);
	}
	100% {
		transform: scale(1);
	}
}
