.toggle__modal {
	color: $primary-color;
	cursor: pointer;
	margin-top: 8px;
	transition: all 300ms ease;
}

.toggle__modal:hover {
	color: #033017;
}

li.check__item {
	list-style-type: none;
}

.modal,
.modal__body,
.modal__content {
	margin: 0;
	padding: 0;
	border: none;
}

.modal__body {
	margin: 0;
	background: url('../../../../../assets/img/cloud-bg.svg');
	background-color: #3f3d56;
	max-height: 85vh;
	padding: 20px 0 40px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.toggle__modal .modal-dialog {
	max-width: 1400px;
}

.modal__header--text {
	color: $primary-color;
	font-size: $font-size-h2;
	padding: 24px 0;
	font-weight: bold;
}

.modal__header,
.modal__body {
	border: none;
}

.modal__body {
	display: flex;
	justify-content: center;
	position: relative;
}

.modal__close {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 24px;
	border: none;
	background-color: transparent;
	color: $default-body-bg;
	transition: all 100ms ease;

	&:hover {
		transform: scale(1.3);
	}
}

.plans__wrapper {
	display: flex;
}

.plan {
	display: flex;
	flex-direction: column;
	max-width: 380px;
	background-color: white;
	border-radius: 30px;
	padding: 32px;
	margin: 0 12px;
	box-shadow: rgba(0, 0, 0, 0.7) 0px 25px 20px -20px;
	transition: all 300ms ease;
}

.plan:hover {
	background-color: $primary-color;
	transform: translateY(-30px);
	background: url('../../../../../assets/img/Meteor.svg');

	.plan__title,
	.plan__para,
	.price__month,
	.price__symbol,
	.price__num,
	.check__text,
	.check__icon,
	.check__icon-2,
	.check__icon-3 {
		color: white;
	}

	.plan__btn {
		color: #fff;
		background-color: #23583a;
	}
}

.plan__title,
.check__icon {
	color: $primary-color;
}

.plan__title {
	font-size: $font-size-h2;
}

.plan__para,
.price__month,
.price__symbol {
	color: #a6a0ae;
}

.plan__para {
	max-width: 340px;
	margin: 12px auto;
}

.price__div {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 24px 0;
}

.price__num {
	color: $primary-color;
	margin: 0 8px;
}

.price__symbol {
	font-size: $font-size-h5;
	position: relative;
	top: -10px;
}

.price__month {
	font-size: $font-size-h6;
}

.check__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.check__item {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
}

.check__icon {
	font-size: 20px;
}

.check__text {
	margin: 0 24px;
	color: #636363;
	font-size: 16px;
}

.plan__btn {
	margin: 60px auto 0 auto;
	background-color: #f5f5f5;
	border: none;
	color: #57dd93;
	font-weight: bold;
	font-size: 14px;
	height: 50px;
	width: 180px;
	border-radius: 24px;
	box-shadow: rgba(0, 0, 0, 0.7) 0px 25px 20px -20px;
	transition: all 300ms ease;
}

@media (max-width: 1110px) {
	.plans__wrapper {
		display: flex;
		flex-direction: column;
	}
	.plan {
		margin: 12px 0;
	}
	.modal__body {
		max-height: 300vh;
		background: #3f3d56;
	}
	.modal-dialog {
		margin: 40px 240px;
	}
}

@media (max-width: 880px) {
	.modal-dialog {
		margin: 40px 120px;
	}
	.plan {
		max-width: 360px;
		padding: 24px;
	}
	.plan__btn {
		margin: 10px auto 0 auto;
	}
	.modal__header {
		font-size: 24px;
	}
}

@media (max-width: 635px) {
	.modal-dialog {
		margin: 16px;
	}
	.plan {
		max-width: 320px;
		padding: 20px;
	}
}
