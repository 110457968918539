.card.stats-card {
  border-radius: $border-radius-extreme;
  // box-shadow: 0px 8px 16px -9px grey;
  min-width: 0;
  height: 160px;
  border: none;
  cursor: pointer;

  .dark-mode & {
    background-color: #181a1b;
    box-shadow: 0px 0px 1px 1px grey;
  }
}

.stats-card .card-body {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.stats-card {
  background-repeat: no-repeat;
  background-size: cover;
}

.small-card-badge-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
}

// .line-bg-1 {
//   // background: url("../../../../../../assets/img/card-bg1.svg") ;
//   background: url("../../../../../../assets/img/rounded1.svg") ;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

// .line-bg-2 {
//   // background: url("../../../../../../assets/img/card-bg2.svg");
//   background: url("../../../../../../assets/img/rounded2.svg") ;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

// .line-bg-3 {
//   // background: url("../../../../../../assets/img/card-bg3.svg");
//   background: url("../../../../../../assets/img/rounded3.svg") ;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

// .line-bg-4 {
//   // background: url("../../../../../../assets/img/card-bg4.svg");
//   background: url("../../../../../../assets/img/rounded4.svg") ;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

.dashboard {
  .stats-card:hover {
    background-color: #250245 !important;
    transition: background-color 400ms ease-in-out;

    * {
      color: white !important;
      transition: all 300ms !important;
    }
  }
}

.text-color {
  color: #250245;
}

.small-card-title {
  color: rgb(81, 81, 81) !important;
  // font-weight: bolder !important;
}

.card-icon {
  font-size: 52px !important;
}

.col-9 {
  position: relative;
}

.center-margin {
  margin-bottom: 3px;
  margin-right: 10px;
}

.align-margin-left {
  margin-left: -10px;
}

.action-text {
  opacity: 0.4;
}
