@import './SignedIn/Dashboard/Dashboard.scss';
@import './SignedIn/Locations/Locations.scss';
@import './SignedIn/SingleLocation/SingleLocation.scss';
@import './SignedIn/Forms/Forms.scss';
@import './SignedIn/SingleForm/SingleForm.scss';
@import 'Login/Login.scss';
@import './SignedIn/Profile/Profile.scss';
@import './SignedIn/Plans/Plans.scss';
@import './Landing/Landing.scss';
@import './SignedIn/Admin/Admin.scss';
@import './SignedIn/AssetProfile/AssetProfile.scss';

* {
    font-family: Noto Sans, sans-serif !important;
    font-weight: normal;
}