@import 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import 'assets/scss/variables.scss';
@import 'views/views.scss';
@import 'components/components.scss';
@import 'layouts/SignedIn/SignedIn.scss';

html,
body {
	overscroll-behavior-y: none;
	height: 100vh;
	background-color: $default-body-bg;
}

body {
	// overflow-y: scroll;
	overflow-y: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 12px;
		height: 12px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #250245;
		border-radius: 6px;
		border: 3px solid #f1f1f1;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: rgb(73, 43, 129);
	}
}

// ::-webkit-scrollbar {
// 	-webkit-appearance: none;
// 	width: 7px;
// }
// ::-webkit-scrollbar-thumb {
// 	border-radius: 4px;
// 	background-color: rgba(0, 0, 0, 0.5);
// 	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
// }
