.general-tab form input,
.general-tab form select {
	background-color: #ffffff;
	margin-bottom: 10px;
}

.gen-tab-map {
	background-color: white;
	border-radius: $border-radius-extreme;
	height: 456px;
}

.gen-tab-submission {
	background-color: white;
	border-radius: $border-radius-extreme;
}

.general-tab .card {
	border: none;
}

.general-tab .map {
	height: 400px;
}

.gen-info-card input:disabled {
	background-color: white;
	border: none;
	font-weight: bold;
}

.general-tab .gen-icon {
	font-size: 28px;
	transition: all 400ms ease;
}

.general-tab .gen-icon-wrapper {
	width: 56px;
	height: 56px;
	background-color: rgb(195, 195, 195);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 400ms ease;
}

.gen-info-card-wrapper,
.gen-tab-map {
	box-shadow: 0px 8px 16px -9px grey;
}

.gen-tab-map {
	border-radius: $border-radius-extreme;
}

.gen-card-title {
	color: $font-color;
}

.edit-gen-info-button {
	margin-right: 12px;
}

.progress-bar {
	background-color: #250245;
	transition: all 400ms ease;
}

.progress {
	height: 16px;
	background-color: #747474;
	border-radius: 50px;
}
.progress-bar-color {
	position: relative;
}
.progress-bar-color::after {
	content: '';
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 3px solid #747474;
	border-radius: 50px;
}

// Stats card styling

.card.gen-info-stats-card {
	border-radius: $border-radius-extreme;
	box-shadow: 0px 8px 16px -9px grey;
	min-width: 0;
	height: 168px;
	border: none;
}

.card.gen-info-stats-card:hover {
	background-color: #250245 !important;
	transition: all 400ms ease;

	.gen-icon-wrapper {
		background-color: #228b22;
	}
	* {
		color: white;
	}
	.progress-bar {
		background-color: #228b22;
	}
}

.gen-info-stats-card .card-body {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.gen-info-stats-card .card-footer {
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.center-margin {
	margin-bottom: 3px;
	margin-right: 10px;
}

.align-margin-left {
	margin-left: -10px;
}

.action-text {
	opacity: 0.4;
}

// @media (max-width: 575px) {
// 	.card.gen-info-stats-card {
// 	}
// }

// @media (min-width: 767px) {
// 	.card.gen-info-stats-card {
// 		max-width: 330px;
// 	}
// }

.width-fit-content {
	width: fit-content;
}

.building-health-score-container {
	position: absolute;
	top: 22px;
	left: 9px;
}

.button-fit-content {
	width: fit-content;
}

.absorbed-tickets-title {
	font-size: 28px;
	font-weight: bold;
}

#upload-tickets,
#upload-locations,
#upload-locations-schedule {
	display: none;
}

.upload-pm-btn {
	border-radius: 5px;
	border: none;
	display: block;
	cursor: pointer;
	width: 190px;
	background-color: #228b22;
	transition: all 200ms;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: #064b06;
	}
}

.upload-pm-label {
	white-space: nowrap;
	font-size: 15px;
	padding: 8px 4px;
	color: white;
	height: 100%;
	cursor: pointer;
}

.edit-submission-btn {
	border: none;
	border-radius: 8px;
	background-color: #fff;

	&:hover {
		background-color: rgba(128, 128, 128, 0.125);
	}
}

// .latest-submissions-table {
//   tbody {
//     tr {
//       cursor: pointer;
//     }
//   }
// }

@media (min-width: 992px) {
	.vh-75 {
		max-height: 75vh;
	}
	.sub-vh-75 {
		max-height: 34.5vh;
		overflow-y: auto;
	}
	.gen-tab-responsive-height {
		height: 50%;
	}
}
