.gray-background {
	background-color: #f2f2f2;
	.dark-mode & {
		background-color: $default-body-bg-dark !important;
		color: #ddd !important;
		border-color: #4a4a4a !important;
	}
}
.set-table-height {
	max-height: 75vh;
	padding-bottom: 0 !important;
}
.title {
	padding: 15px;
	opacity: 0.7;
}

.table-btn {
	background-color: transparent;
	border: none;
	color: #696969;
	border-radius: 50%;

	&:hover {
		transform: scale(1.2);
		filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.25));
	}

	&:focus {
		background-color: unset;
		box-shadow: none;
		color: #696969;
	}
}

.searchbar {
	border: none;
	padding: 4px 0 4px 15px;
	background-color: #f4f4f4;
	outline: none;

	.dark-mode & {
		background-color: #1e2122 !important;
		color: #ddd !important;
	}
}

.locations-table-body {
	overflow: scroll;
}
.dark-mode {
	.locations-table-body {
		overflow: scroll;

		&::-webkit-scrollbar {
			width: auto;
		}
		&::-webkit-scrollbar-track {
			background: #202324;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #454a4d;
			border: none;
		}
		&::-webkit-scrollbar-corner {
			background-color: #181a1b;
		}
	}
}
.background {
	background-color: #f3f3f3;
}
.align-start {
	text-align: start;
}
.align-end {
	text-align: end;
}
.larger-font-icon {
	font-size: 18px;
}

.no-border {
	border: none;
	color: #696969;
}

// .no-border:hover {
//   background-color: transparent;
//   color: black;
// }

.clickable {
	cursor: pointer;
}

.larger-font {
	font-size: 22px;
}

.locations-card {
	border-radius: $border-radius-extreme;
	// box-shadow: 0px 8px 16px -9px grey;
	// min-width: 0;
	// height: 125px;

	.dark-mode & {
		background-color: $default-body-bg-dark;
		border-color: grey;

		th,
		td,
		button {
			color: #ddd !important;
			background-color: #181a1b !important;
		}
	}
}

.locations-card .card-header {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.locations-card .card-footer,
.location-tables {
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

table.locations-table > thead > tr > th,
table.locations-table > tbody > tr > td {
	text-align: center;
	font-size: 15px;
}

table.locations-table {
	table-layout: fixed;
}

.is-bold-true {
	font-weight: 900;
}

.is-bold-true,
.is-bold-false {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

// // // Filter Styling // // //

$cubic-out: cubic-bezier(0.32, 2.04, 0.85, 0.54);
$cubic-in: cubic-bezier(0.72, 1.2, 0.71, 0.72);
$nunito: 'Nunito', sans-serif;
$roboto: Roboto, sans-serif;

$cyan: #00bcd4;
$yellow: #ffeb3b;
$grey: #9e9e9e;

$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);

.kebab-wrapper {
	cursor: pointer;
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	padding: 0;
	top: 12px;
}

.table-btn:hover > .kebab-wrapper {
	transform: scale(0.8333);  /* 1 / 1.2 = 0.8333 */
}

.filter-title {
	transition: all 300ms ease !important;
}

.filter-btn {
	background-color: transparent;
	position: relative;
	padding: 6px 12px;
	width: 46px;
	height: 100%;
}

.filter-close,
.filter-close-expand,
.filter-dots {
	position: absolute;
	width: 28px;
	height: 28px;
	right: 4px;
}
.filter-dots {
	font-size: 22px !important;
	padding: 3px;
}

.filter-close,
.filter-close-expand {
	color: white;
	border-radius: 50%;
	padding: 6px;
	background-color: #228b22;
}

.filter-close {
	transform: scale(0);
	transition: all 200ms ease;
}

.filter-close-expand {
	transform: scale(1);
	transition: all 200ms ease;
}

.hover:hover {
	background-color: #f3f3f3;
}

.kebab {
	padding: 10px;
	position: absolute;
	z-index: 3;
	right: 0;
	transition: all 0.25s ease-out;
	transform: scale(0);
	transform-origin: 100% 0;
	box-shadow: $shadow;
	width: 320px;
	border-radius: 10px;
}

.kebab.active {
	transform: scale(1);
	transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
	background-color: white;
}

// // // Toggle Button Styling // // //

.filter-input[type='checkbox'] {
	height: 0;
	width: 0;
	visibility: hidden;
}
.filter-label {
	cursor: pointer;
	width: 35px;
	height: 20px;
	background: grey;
	display: block;
	border-radius: 100px;
	margin: 0px;
	position: relative;
}
.filter-label:after {
	content: '';
	position: absolute;
	top: 2.5px;
	left: 2.5px;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 20px;
	transition: 0.3s;
}
.filter-input:checked + .filter-label {
	background: #228b22;
}
.filter-input:checked + .filter-label:after {
	left: calc(100% - 2.5px);
	transform: translateX(-100%);
}

.filter-toggle {
	height: 20px;
}

@media (max-width: 388px) {
	.filter-close,
	.filter-close-expand,
	.filter-dots {
		right: 8px;
	}
	.kebab {
		width: 240px;
	}
	.filter-title {
		font-size: 14px;
	}
}

// Location Health Bar

.locationHealth {
	height: 8px !important;
	border: 2px solid #747474;
	transition: all 400ms ease;
	max-width: 200px;
	margin: 8px auto 0;
}

.bg-greenHealth {
	background-color: #228b22 !important;
}

.bg-yellowHealth {
	background-color: #e7c012 !important;
}

.bg-redHealth {
	background-color: #eb0f11 !important;
}

@media (max-width: 1300px) {
	.locations-table {
		table-layout: auto !important;
	}
}

.input-styling {
	width: 100%;
	border-radius: 4px;
	padding: 6px;
	border: 1px solid rgb(192, 192, 192);

	&:focus {
		border: 1px solid rgb(192, 192, 192);
	}
}

.pac-container {
	z-index: 1052 !important;
}
