@media (min-width: 768px) {
	.abs-center-x {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
}
.asset-types-table {
	table-layout: fixed;
}
