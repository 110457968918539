.profile-page {
  .card-header {
    border: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  tr {
    cursor: pointer;
  }
  tr:hover {
    background-color: rgb(219, 219, 219);
  }
  .progress {
    height: 16px !important;
    padding: 0;
    width: 100%;
  }
  .progress-bar {
    background-color: #228b22;
  }
  .card {
    border: none;
    box-shadow: 0px 8px 16px -9px grey;
  }

  .profile-category {
    color: rgb(95, 99, 104);
    line-height: 1rem;
    font-weight: bolder;
    letter-spacing: 0.01rem;
    font-size: 14px;
  }

  .profile-photos-card {
    min-height: 200px;
    overflow: hidden;
    border-radius: 20px;
  }

  .profile-cover-img-wrapper {
    padding: 20px;
    min-height: 150px;
    position: relative;
    border-radius: 20px;
    margin: 10px 20px;
  }
  input {
    outline: none;
    background-color: transparent;
    padding: 8px 6px;
    border-radius: 5px;
    border: none;
    width: 100%;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: #e4e2e2;
      cursor: default;
    }

    &:focus {
      box-shadow: none;
      background-color: #e4e2e2;
      cursor: text;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #e4e2e2;
    }
  }
}

.profile-btn {
  background-color: #228b22;
  border: none;
}

.toast {
  position: absolute;
  top: 80%;
  left: 50%;
}

.profile-picture-wrapper {
  border-radius: 50%;
  height: 96px;
  width: 96px;
  overflow: hidden;
  padding: 0;
  position: relative;
  img {
    height: 100%;
    width: 100%;
  }
}

.profile-modal-toggle {
  cursor: pointer;
  color: rgb(121, 121, 121);
  font-weight: bolder;
  letter-spacing: 0.01rem;
  font-size: 13px;
  padding: 4px 6px;
  border-radius: 4px;

  &:hover {
    background-color: #7c7c7c;
    color: rgb(255, 255, 255);
  }
}

.line-break {
  border-top: 1px solid rgb(218, 220, 224);
}

.text-purple {
  color: #250245;
}

.border-none {
  border: none;
}

#password-modal,
#email-modal {
  max-width: 450px;
  border: none !important;

  input {
    outline: none;
    background-color: transparent;
    padding: 8px 6px;
    border-radius: 5px;
    border: none;
    width: 100%;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: #e4e2e2;
      cursor: default;
    }

    &:focus {
      box-shadow: none;
      background-color: #e4e2e2;
      cursor: text;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #e4e2e2;
    }
  }
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #6c757d;
  background-color: transparent;
  padding: 0;
  border: none;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-input-btns-wrapper {
  position: absolute;
  right: 0;
  bottom: -40px;
  gap: 20px;
  z-index: 100;
}

.profile-input-btn {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.55);
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0 4px;
  transition: 100ms;
  color: #6c757d;

  &:hover {
    background-color: #dad9d9;
  }

  &:active {
    background-color: #228b2236;
    color: #228b22;
  }
}

.error-text {
  color: rgb(174, 0, 0);
  font-size: 12px;
}

.align-profile {
  left: 50% !important;
  bottom: 20px;
  transform: translate(-50%, 0) !important;
  position: absolute !important;
}



.dark-mode {
  .profile-page {
    h5 {
      color: #ddd !important;
    }
    h6, span {
      color: #bbb !important;
    }
    .card, .card-body {
      background-color: #181a1b !important;
    }

    input {
      color: #ddd;
      &:hover {
        background-color: #282b2d !important;
      }
    }

    input:disabled, select {
      color: #ddd !important;
      background-color: #282b2d !important;
      border-color: #282b2d !important;
    }
  }
}