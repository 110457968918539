.dark-mode {
	body .page {
		background-color: $default-body-bg-dark;
	}
}

.main-page-container {
	border-top-left-radius: 40px;
	border-bottom-left-radius: 40px;
	overflow: hidden;
	position: relative;
	transition: box-shadow 0ms linear;
	transition: margin-left 0.2s linear;
	.dark-mode & {
		box-shadow: 0 0 0 250px $navbar-bg-purple-dark;
		background-color: $default-body-bg-dark;
	}
	box-shadow: 0 0 0 250px #250245;
	padding-bottom: 72px !important;
}

@media print {
	.rounded-corners {
		box-shadow: none;
	}
}

.main-page-close {
	margin-left: 70px !important;
}

.toggle-button {
	position: absolute;
	font-size: 20px;
	cursor: pointer;
	transition: all 100ms ease;

	.dark-mode & {
		color: #d2cec8;
	}
	left: 1px;
	top: 15px;
}

.toggle-button:active {
	transform: scale(0.95);
}

.copyright {
	position: absolute;
	color: #626262;
	font-size: 12px;
	cursor: default;
	bottom: 8px;
	left: 50%;
	transform: translate(-50%, 0);

	& > * {
		white-space: nowrap;
	}
}

@media (max-width: 767px) {
	.toggle-button {
		visibility: hidden;
		z-index: -1;
	}
	.main-page-container {
		background-color: $default-body-bg;
		width: 100vw;
		margin-left: 0px;
		border-radius: 0;

		.nav-open {
			.sidebar {
				display: none;
			}
		}
	}
}

@media (min-width: 768px) {
	.main-page-container {
		background-color: $default-body-bg;
		width: calc(100vw - 250px);
		margin-left: 250px;
		.dark-mode & {
			background-color: $default-body-bg-dark;
		}
	}
}

@media (max-width: 768px) {
	.main-page-container {
		margin-left: 0 !important;
	}
	.chatbot-main-card {
		margin-top: 32px;
	}
}
