.photo {
	width: 52px;
	height: 52px;
	border-radius: 50%;
	border: 4px solid transparent;
	background: linear-gradient(darkblue, darkblue) padding-box,
		linear-gradient(to bottom, rgb(0, 183, 255), rgb(0, 255, 128))
			border-box;
	overflow: hidden;
	margin: 20px auto 8px auto;
}

.avatar {
	width: 100%;
}

.sidebar-username {
	margin-top: 4px;
	margin-right: 4px;
	opacity: 0.8;
	transition: all 200ms ease;
	white-space: nowrap;
}

.arrow-icon-up-wrapper,
.arrow-icon-down-wrapper {
	display: inline-block;
	font-size: 24px;
	transition: all 300ms ease;
}

.arrow-icon-down-wrapper {
	transform: rotate(180deg);
}

.sidebar-username-wrapper {
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.arrow-icon {
	transition: all 200ms ease;
	opacity: 0.8;
}

.sidebar-username-wrapper:hover .arrow-icon {
	transform: scale(1.2);
	opacity: 1 !important;
}

.sidebar-username-wrapper:hover .sidebar-username {
	opacity: 1;
}

.sidebar {
	background-color: $navbar-bg-purple;
	width: 70px;
	position: relative;
	height: 100%;
	transition: width 0.2s linear;

	.dark-mode & {
		background-color: $navbar-bg-purple-dark;
	}
	margin-top: 10px;
}

.sidebar-expanded {
	width: 250px !important;
}

.user {
	position: relative;

	a {
		color: $white-color;
		text-decoration: none;
		white-space: nowrap;
	}

	.info {
		> span {
			display: block;
			line-height: 18px;

			> span {
				display: block;
				position: relative;
				opacity: 1;
			}
		}

		.caret {
			position: absolute;
			top: 8px;
			right: 18px;
		}
	}
}

.sidebar {
	min-height: 100vh;
	max-height: 400vh;
	z-index: 1;
	.sidebar-normal {
		transition: all 250ms ease;
		margin: 20px 0;
		padding-left: 20px;
		position: relative;
		min-height: 32px;
		border-radius: 40px;
		line-height: 20px;

		a {
			color: $white-color;
			display: block;
			text-decoration: none;
			position: relative;
			cursor: pointer;
			font-size: 12px;
			line-height: 30px;
		}

		.active,
		.inactive {
			font-size: 14px;
			display: flex;
			align-items: center;
			padding: 0;
			margin-left: 20px;
			margin-bottom: 0;
			// transition: all 200ms ease;
			height: 32px;
		}

		.active {
			background-color: transparent;
			color: $navbar-active-link !important;
			font-weight: bold;
			opacity: 1 !important;
			.dark-mode & {
				color: $navbar-active-link-dark !important;
			}
	  
		}

		.inactive {
			background-color: transparent;
			opacity: 0.8;
		}
	}
	.sidebar-normal-collapsed {
		padding: 0;
	}

	.sidebar-profile-item,
	.sidebar-topic-item {
		white-space: nowrap;
		margin-top: 2px;
	}

	.sidebar-mini-icon {
		text-transform: uppercase;
		width: 24px;
		margin-right: 20px;
		margin-left: 0px;
		//margin-top: 4px;
		font-size: 24px;
		text-align: center;
		line-height: 20px;
		position: relative;
		float: left;
		z-index: 1;
		display: inherit;
	}

	.inactive:hover {
		opacity: 1;
		.log-out-text,
		.log-out-icon {
			opacity: 1 !important;
		}
	}

	.nav {
		display: block;

		.caret {
			top: 14px;
			position: absolute;
			right: 5px;
		}

		p {
			margin: 0;
			line-height: 30px;
			position: relative;
			display: block;
			height: auto;
			white-space: nowrap;
			font-weight: 600;
		}

		i {
			font-size: 24px;
			float: left;
			margin-right: 12px;
			line-height: 30px;
			width: 34px;
			text-align: center;
			color: $opacity-5;
			position: relative;
		}
	}
}

.user-name {
	font-size: 11.5px;
}

.sidebar-normal > .active > .blend-effect {
	height: 45px;
	width: 75px;
	z-index: -100;
	background-color: $default-body-bg;
	border-radius: 40px;
	position: absolute;
	top: -5px;
	left: -15px;
	.dark-mode & {
		background-color: $default-body-bg-dark;
	}
}

.sidebar-normal > .active > .blend-effect::before,
.sidebar-normal > .active > .blend-effect::after {
	content: '';
	position: absolute;
	background: transparent;
	right: 10px;
	height: 75px;
	width: 35px;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
}

.sidebar-normal > .active > .blend-effect::before {
	bottom: -75px;
	box-shadow: 5px -25px 0 0 $default-body-bg;
	.dark-mode & {
		box-shadow: 5px -25px 0 0 $default-body-bg-dark;
	}
}

.sidebar-normal > .active > .blend-effect::after {
	top: -75px;
	box-shadow: 5px 25px 0 0 $default-body-bg;
	.dark-mode & {
		box-shadow: 5px 25px 0 0 $default-body-bg-dark;
	}
}

.collapsing {
	.sidebar-normal > .active > .blend-effect-top {
		position: absolute;
		background: yellow !important;
		right: -20px;
		z-index: 100;
		transform: translateY(-10px);
		top: -110px;
		height: 75px;
		width: 30px;
		border-top-right-radius: 80px;
		border-bottom-right-radius: 80px;
		box-shadow: 5px 40px 0 0 $navbar-bg-purple;
		.dark-mode & {
			box-shadow: 5px 40px 0 0 $navbar-bg-purple-dark;
		}
	}
}

.log-out-text,
.log-out-icon {
	color: white !important;
}

.log-out {
	cursor: pointer;
}

.sidebar {
	.dropdown {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		width: 250px;
		button {
			font-size: 15px;
			background-color: transparent;
			border: none;
			color: rgba(255, 255, 255, 0.8);
			transition: all 200ms ease;
			&:hover {
				color: white;
			}
			&:focus {
				box-shadow: none;
			}
		}
	}
	.dropdown-menu {
		background-color: transparent;
		width: 200px;
		border: 2px solid rgba(255, 255, 255, 0.8);
		padding: 0;
		overflow: hidden;
		transform: translate(25px, -45px) !important;
	}
	.dropdown-menu.show {
		overflow-y: scroll;
		height: 240px;

		&::-webkit-scrollbar {
			width: 3px;
		}
		&::-webkit-scrollbar-track {
			overflow: hidden;
		}
		&::-webkit-scrollbar-thumb {
			background: rgba(255, 255, 255, 0.8);
			border-radius: 100px;
		}
	}
	.dropdown-item {
		color: rgba(255, 255, 255, 0.8);
		padding: 0;
		padding: 8px;
		text-align: center;
		font-size: 15px;
	}
}

.hidden {
	display: none;
}

.sidebar-item-wrapper {
	height: 32px;
}


.blend-effect {
	transition: width 0.2s linear;
}

.blend-effect-expanded {
	width: 235px !important;
}