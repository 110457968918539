.dashboard {
	* {
		font-weight: lighter;
	}
}
.dashboard-bg {
	background-color: $default-body-bg;
}

.row-1 {
	margin-top: -1rem;
}

.title-text {
	text-transform: capitalize;
	font-size: 25px;
}

.title {
	width: 350px;
}

// card shadows + bg

.health-card,
.medium-card,
.large-card,
.stats-card {
	box-shadow: 0px 8px 16px -9px grey;
}

.health-card,
.medium-card,
.large-card,
.card-footer,
.card-header,
.medium-card-alt {
	background-color: $default-body-bg !important;
	.dark-mode & {
		background-color: $default-body-bg-dark !important;
	}
}

.bg-light {
	background-color: #fff !important;
}

// responsiveness

@media (max-width: 1460px) {
	.footer-item-title {
		font-size: 14px !important;
	}
	.footer-item-value {
		font-size: 20px;
	}
	.footer-icon-wrapper {
		width: 40px;
		height: 40px;
	}
	.main-card-footer-icon {
		font-size: 20px;
	}
}

@media (max-width: 770px) {
	.row-1 {
		display: flex;
		flex-direction: column;
	}
}

// animations

.hidden {
	opacity: 0;
	filter: blur(5px);
	transform: translateX(-100%);
}

.show {
	opacity: 1;
	filter: blur(0);
	transform: translateX(0);
}

#svisits {
	transition: transform 1s 0s, filter 1s 0s, opacity 1s 0s, color 1s 0s,
		background-color 1s 0s;
	.dark-mode & {
		* {
			color: #d2cec8 !important;
			.badge,
			.card-title p {
				color: #fff !important;
			}
		}
		&:hover * {
			color: #fff !important;
		}
	}
}

#avglifespan {
	transition: transform 1s 200ms, filter 1s 200ms, opacity 1s 200ms,
		color 1s 0s, background-color 1s 0s;
	.dark-mode & {
		* {
			color: #75dd75 !important;
			.badge,
			.card-title p {
				color: #fff !important;
			}
			.badge {
				background-color: #228b22 !important;
			}
		}
		&:hover * {
			color: #fff !important;
		}
	}
}
#activeloc {
	transition: transform 1s 400ms, filter 1s 400ms, opacity 1s 400ms,
		color 1s 0s, background-color 1s 0s;
	.dark-mode & {
		* {
			color: #1ad1ff !important;
			.badge,
			.card-title p {
				color: #fff !important;
			}
		}
		&:hover * {
			color: #fff !important;
		}
		.badge {
			background: #00a3cc !important;
		}
	}
}
#alerts {
	transition: transform 1s 600ms, filter 1s 600ms, opacity 1s 600ms,
		color 1s 0s, background-color 1s 0s;
	.dark-mode & {
		* {
			color: #fbc658 !important;
			.badge,
			.card-title p {
				color: #fff !important;
			}
		}
		&:hover * {
			color: #fff !important;
		}
		.badge {
			background-color: #714d03 !important;
		}
	}
}

// medium cards
#maintenance {
	transition: transform 1s 400ms, filter 1s 400ms, opacity 1s 400ms,
		color 1s 0s, background-color 1s 0s;
	.dark-mode & {
		box-shadow: 0px 0px 1px 1px grey;
		* {
			background: #181a1b !important;
			color: #75dd75 !important;
			.badge {
				background: #228b22 !important;
				color: #fff !important;
			}
		}
	}
}
#replace {
	transition: transform 1s 600ms, filter 1s 600ms, opacity 1s 600ms,
		color 1s 0s, background-color 1s 0s;
	.dark-mode & {
		box-shadow: 0px 0px 1px 1px grey;
		* {
			background: #181a1b !important;
			color: #1ad1ff !important;
			.badge {
				background: #00a3cc !important;
				color: #fff !important;
			}
		}
	}
}
#downtime {
	transition: transform 1s 800ms, filter 1s 800ms, opacity 1s 800ms,
		color 1s 0s, background-color 1s 0s;
	.dark-mode & {
		box-shadow: 0px 0px 1px 1px grey;
		* {
			background: #181a1b !important;
			color: #fbc658 !important;
			.badge {
				background: #714d03 !important;
				color: #fff !important;
			}
		}
	}
}

//overview card

#dashboard-overview {
	transition: transform 600ms 0ms, filter 600ms 0ms, opacity 600ms 0ms;
}

.cost-savings-card {
	transition: transform 1000ms 0ms, filter 1000ms 0ms, opacity 1000ms 0ms;
}

.alfreds-notes {
	height: 100%;
	max-height: 410px;
	width: 100%;
	padding: 14px 20px;
	border: none;
	box-shadow: 0px 8px 16px -9px grey;
	border-radius: 10px;
	position: relative;
	overflow: hidden;

	.dark-mode & {
		background-color: #181a1b;
		border: none;
		box-shadow: 0px 0px 1px 1px grey;

		h3,
		p {
			color: rgb(210, 206, 200);
		}
	}
	.alfreds-health-score {
		max-width: 200px;
		padding: 12px;
		position: absolute;
		right: 5px;
		top: 10px;
	}
}

.alfred-populate-card {
	background-color: #f2f2f2;
	min-height: 310px;
	box-shadow: 0px 8px 16px -5px grey;
	border: none;
	border-radius: 10px;
}

.kpi-card {
	border: none;
	box-shadow: 0px 8px 16px -5px grey;
	border-radius: 10px;
	background-color: #fff;
	min-height: 310px;

	.card-header {
		background-color: #fff;
		border: none !important;
	}
}

.alfreds-notes-title {
	flex: none;
	h3 {
		font-weight: bold;
		margin-bottom: 1rem;
	}
}

.alfreds-notes-text {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;

	padding-left: 12px;
	padding-right: 12px;
	margin-top: 0;
}

.summary-note {
	color: #0c0c0c;
	font-weight: 600;
	font-size: 1rem;
	padding-left: 16px;
	padding-right: 12px;
	padding-bottom: 6px;
	padding-top: 6px;

	.dark-mode & {
		color: #e0deda;
	}
}

.summary-note-container {
	background-color: rgb(242, 242, 242);
	box-shadow: 0px 8px 16px -9px grey;

	border-radius: 20px;
	margin-bottom: 16px;

	.dark-mode & {
		background-color: #1f2223;
		box-shadow: #60686c 0px 8px 16px -9px;
	}
}

.alfreds-notes-loader {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 75%;
	justify-content: center;
	align-items: center;
}

.alfreds-health-score-box {
	position: relative;
	top: 0;
	left: 0;
}

.pe-footer-item {
	font-size: 13px;
	color: #626262;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
		color: #626262;
	}
	&:visited {
		text-decoration: none;
	}
	&:active {
		text-decoration: none;
	}
	&:link {
		text-decoration: none;
	}
}

.pe-footer-item-2 {
	font-size: 13px;
	color: #626262;
}

@media (max-width: 768px) {
	.chatbot-main-card {
		margin-bottom: 40px;
	}
	.alfreds-notes {
		max-height: unset;
		.alfreds-health-score {
			position: relative;
			top: 0;
			left: 0;
			margin: 0 auto;
		}
	}

	.alfreds-notes-title {
		margin-top: 18rem;
	}

	.alfreds-health-score-box {
		position: absolute;
		/* right: 0; */
		width: 100%;
		left: 0;
		top: 2rem;
		margin: 0 0 4rem;
	}

	.alfreds-notes-text {
		padding-right: 0;
		margin-top: 0;
	}
}
