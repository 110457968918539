.medium-card,
.medium-card-alt {
  height: 340px;
  border-radius: $border-radius-extreme;
  // box-shadow: 0px 8px 16px -9px grey;
  border: none !important;
}

.medium-card .card-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgb(248, 249, 250);
  border: none;
}

.medium-card .card-body {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: rgb(248, 249, 250);
  overflow: hidden;
}

.medium-card .card-footer {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: rgb(248, 249, 250);
  // box-shadow: 0px 8px 16px -9px grey;
}

.chart {
  background-color: rgb(248, 249, 250);
}

.align-right {
  float: right;
}

.medium-icon {
  font-size: 44px !important;
}

.bg-transition,
.text-transition,
.chart,
.medium-icon {
  transition: all 400ms ease;
}

.medium-card:hover {
  .medium-icon {
    color: white !important;
  }
  .bg-transition {
    background-color: #250245 !important ;
  }
  .chart {
    background-color: #250245 !important ;
  }
  .text-transition {
    color: white !important ;
  }
}
