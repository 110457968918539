.locations-bg {
  background-color: $default-body-bg;
}

.dark-mode {
  .activities-page-buttons {
    color: #ddd;
    button {
      background-color: #4a4a4a !important;
      border-color: #4a4a4a !important;
      color: #ddd;
    }
    div {
      border-color: $default-body-bg-dark !important;
    }
  }
  .tab-pane {
    .card {
      background-color: $default-body-bg-dark;
      border-color: #4a4a4a;
      td, th, div {
        color: #ddd !important;
        border-color: #4a4a4a;
      }
      input {
        background-color: #1e2122 !important;
        color: #ddd !important;
      }
      input[type="checkbox"] {
        border-color: #4a4a4a;
      }
      .table-btn, button, input[type="button"] {
        color: #ddd !important;
        background-color: $default-body-bg-dark !important;
      }
      select {
        color: #ddd;
        background-color: $default-body-bg-dark;
        border-color: #4a4a4a;
      }
      .card-header {
        background-color: $default-body-bg-dark !important;
        color: #ddd !important;
      }
      .btn-purple {
        background-color: #4a4a4a !important;
        border-color: #4a4a4a !important;
        color: #ddd !important;
      }
      .btn-success {
        color: #ddd !important;
        background-color: #115c39 !important;
        border-color: #22b771 !important;
      }
      .form-control, .form-select {
        background-color: #1e2122 !important;
        color: #ddd !important;
      }
      .form-control:disabled {
        background-color: #25282a !important;
      }
    }
  }
}


.dark-mode {
  .modal-content {
    background-color: $default-body-bg-dark !important;
		border-color: #4b5154 !important;
		color: #ddd !important;

		.modal-header, .modal-footer {
			border-color: #4b5154 !important;
		}
		.btn-close {
			filter: invert(1) !important;
		}
    .btn-secondary {
      color: #ddd !important;
      background-color: #4b5154 !important;
      border-color: #ddd !important;
    }
		.cancel-btn {
			color: #ddd !important;
			background-color: #4b5154 !important;
			border-color: #ddd !important;
		}
		.confirm-btn {
			background-color: #646768 !important;
			color: #fff !important;
			border-color: #646768 !important;
		}
		input, input:autofill, input:-webkit-autofill, select {
			background-color: #1e2122 !important;
			color: #ddd !important;
			border-color: #646768 !important;
		}
	}
}



// .progress::after {
//   content: "";
//   position: absolute;
//   z-index: 100;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   border: 3px solid #747474;
//   border-radius: 50px;
// }

.single-location-health-score {
  width: 100px;
  height: 100px;
  margin-right: 20px;

  .health-title {
    display: none;
  }
}

.progress-bar {
  border-radius: 15px;
}

.single-location-parent {
  table {
    th {
      font-weight: bold;
    }
  }
  .single-location-health-bar {
    position: relative;
    min-width: 300px;
    box-shadow: 0px 0px 20px 5px rgba(151, 16, 204, 0.74);
    transition: all 300ms ease;

    &:hover {
      transform: scale(1.03);
    }
    .progress-bar {
      background: linear-gradient(
        90deg,
        rgba(151, 16, 204, 0.74) 0%,
        rgba(15, 255, 99, 1) 100%
      );
      background-color: #4ce64c;
      position: relative;
    }
  }
  .nav-link {
    height: 42px;
  }
}

.current-health {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 14px;
  z-index: 100;
  position: absolute;
}

.health-bar-red {
  box-shadow: 0px 0px 20px 5px #df3053 !important;

  .progress-bar {
    background: linear-gradient(90deg, #df3053 0%, #df3053 100%) !important;
  }
}
.health-bar-yellow {
  box-shadow: 0px 0px 20px 5px #fbc658 !important;

  .progress-bar {
    background: linear-gradient(90deg, #fbc658 0%, #fbc658 100%) !important;
  }
}

.width-fit-content {
  width: fit-content;
}

.location-back-button {
  cursor: pointer;
  transition: ease-in-out 0.1s;
  border-radius: 8px;
  .dark-mode & {
    color: #ddd;
  }
}

.location-back-button:hover {
  background-color: rgba(128, 128, 128, 0.125);
}

.inactive-tab {
  color: black;
}

.hide-scrollbar {
  overflow: hidden;
}

.tab-content {
  height: 100%;
}

.tab-pane {
  height: 100%;
}
.fullSize {
  width: 100%;
  height: 100%;
  .dark-mode & {
    color: #ddd;
  }
}

.single-location-parent {
  height: calc(100% - 150px);
  .location-tab {
    display: flex;
    white-space: nowrap;
  }
}

.table {
  margin-bottom: 0;
}

.tab-icon {
  display: none;
}

.settings-tab-btn {
  line-height: 1.5;
}

.back-button-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 5px;
  font-size: 14px;
  transform: scale(1.06);
}

.single-location-health-score {
  margin-right: 40px;
  margin-top: 20px;
}

// animated tab bar

.inactive-tab,
.active-tab {
  border-radius: 0;
  border: none !important;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  .dark-mode & {
    color: #e8e6e3;
  }
}

.inactive-tab:hover,
.active-tab:hover {
  border: none !important;
}

.inactive-tab:hover {
  color: #228b22;
  transition: 200ms;
}

.active-tab {
  color: #250245 !important;
  .dark-mode & {
    color: #fff !important;
  }
}

.single-location-navbar-animated,
.navbar-animated {
  // border-bottom: 4px solid rgb(209, 209, 209);
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 4px;
    background-color: rgb(209, 209, 209);
    position: absolute;
    bottom: -4px;
    border-radius: 40px;
    .dark-mode & {
      background-color: #4a4a4a;
    }
  }
}

.tab-indicator {
  position: absolute;
  bottom: -6px;
  height: 4px;
  background-color: #250245;
  transition: all 500ms ease;
  border-radius: 40px;
  .dark-mode & {
    background-color: #ddd;
  }
}

.single-location-navbar-animated-item {
  display: flex;
  justify-content: center;
}

@media (max-width: 764px) {
  .single-location-health-score {
    margin: 20px 0 !important;
  }
}

@media (max-width: 580px) {
  .tab-title,
  .settings-tab-btn {
    display: none;
  }
  .single-location-parent {
    .location-tab {
      width: unset;
      padding: 0px 14px !important;
    }
    .active-tab,
    .inactive-tab {
      padding-top: 6px;
      padding-bottom: 0;
    }
    .single-location-navbar-animated {
      display: flex;
      justify-content: space-evenly;
    }
  }
  .tab-icon {
    display: block;
    font-size: 30px;
  }
  .active-tab .tab-icon {
    color: #228b22 !important;
  }
  .nav-link {
    padding: 4px 8px;
  }
}
@media (max-width: 430px) {
  .tab-icon {
    display: block;
    font-size: 26px;
  }
  .nav-link {
    padding: 4px 0;
  }
}
