.medium-card-alt {
  height: 350px;
  border-radius: $border-radius-extreme;
  box-shadow: 0px 8px 16px -9px grey;
  transition: all 300ms ease;
}

.alt-body {
  background: url(../../../../../../assets/img/altlinebg.svg) !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.medium-card-alt .card-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgb(248, 249, 250);
  border: none;
}

// .medium-card-alt .card-body {
//   border-top-left-radius: 12px;
//   border-top-right-radius: 12px;
//   background-color: rgb(248, 249, 250);
// }

.medium-card-alt .card-footer {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: rgb(248, 249, 250);
  box-shadow: 0px 8px 16px -9px grey;
}

p.no-wrap {
  margin: 0;
  padding: 0;
  font-size: small;
}

.align-right {
  float: right;
}

.card-footer > .row > .col > a {
  font-size: small;
  color: black;
  text-decoration: none;
}

.card-footer > .row > .col > a:hover {
  color: grey;
}

.card-footer > .row > .col > a:hover,
.card-footer > .row > .col > a:visited,
.card-footer > .row > .col > a:active {
  text-decoration: none;
}

.alt-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden;
}

.medium-card-alt tr:hover > * {
  color: white !important;
}

.medium-card-alt:hover {
  .alt-body,
  .alt-header,
  .alt-footer,
  .medium-card-alt {
    background-color: #250245 !important;
    transition: all 300ms ease;
  }
  .text-transition,
  th,
  tr {
    color: white !important;
    transition: all 300ms ease;
  }
}
