.plans__title--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 50;
  margin-top: 120px;

  .plans__title {
    color: $primary-color;
    font-size: $font-size-h1;
    z-index: 50;
    text-align: center;
  }

  .sub-title {
    margin: 40px 0 20px 0;
    font-size: $font-size-h5;
    max-width: 500px;
    color: rgb(79, 79, 79);
    z-index: 50;
  }

  .btn {
    background-color: $primary-color;
    height: 48px;
    border: none;
    font-size: $font-size-xs;
    letter-spacing: 0.1rem;
    z-index: 50;
  }

  .btn:hover {
    color: $primary-color;
    background-color: white;
    border: 2px solid $primary-color;
  }
}

.header__img {
  position: absolute;
  z-index: 1;
  max-width: 500px;
}

.header__img--left,
.header__img--right {
  bottom: 0;
  transform: translateY(340px);
}

.header__img--left {
  left: 0;
}

.header__img--right {
  right: 0;
}

// TABLETS, LARGE PHONES

@media (max-width:1300px) {
  .plans__title--wrapper {
    margin-top: 80px;

    .plans__title {
      font-size: 48px;
    }
    .sub-title {
      font-size: 23px;
    }
  }
  .header__img--left {
    display: none;
  }
  .header__img--right {
    left:50%;
    transform: translate(-50%, 400px);
  }
}

@media (max-width:777px) {
  .sub-title {
    margin: 20px 0 10px 0;
  }
}

// small phones

@media (max-width: 570px) {
  .plans__title--wrapper {
    margin-top: 40px;
  }
  .plans__title {
    font-size: 36px !important;
    margin: 0 auto;
  }
  .sub-title {
    font-size: 18px !important;
  }
  .header__img--right {
    max-width: 370px;
    transform: translate(-50%, 340px);
  }
}
