.asset-profile-table {
	background-color: #f3f3f3;
	border-radius: 10px;
	margin: 0;
	td:nth-child(1) {
		text-align: start;
	}
	tbody {
		tr:last-child {
			border-bottom: transparent;
		}
	}
	.dark-mode & {
		color: #e0deda !important;
		background-color: #343434 !important;
	}

	.table-unit {
		max-width: 180px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		position: relative;

		@media screen and (min-width:767px) {
			max-width: 50px;
		}
	}

	@media screen and (min-width: 1600px){
		.table-unit.overflowing:hover {
			z-index: 1;
			animation: expand 0.5s linear forwards 1;
		}
	}

	// .table-unit:hover {
	// 	z-index: 1;
	// 	// max-width: 300px;
	// 	animation: expand 0.5s linear forwards 1;
	// }

	@keyframes expand {
		0% {
            max-width: 50px;
        }

		100% {
			max-width: 500px;
		}
	}

	@media screen and (max-width:767px) {
		margin-top: 2em;
		tr > *{
			display: block;
		}
		tr {
			display: table-cell;
		}

		thead, tbody, tfoot, tr, td, th {
			height: 50px;
			overflow: hidden;
		}

		td {
			padding-top: 12px;
		}
	}
}

.asset-profile-card {
	@media screen and (min-width:768px) {
		min-height: calc(100vh - 255px);
		display: flex;
		margin: 0 12px;
		padding: 16px;
		border-radius: 20px;
		box-shadow: 0px 8px 16px -9px grey;

		.dark-mode & {
			color: #e0deda !important;
			background-color: #1f2223 !important;
		}
	}
}

.asset-profile-active-img {
	// max-width: 650px;
	min-width: 200px;
	width: 100%;
	object-fit: cover;
	height: 100%;
	max-height: 650px;
	border-radius: 20px;
}

.asset-profile-edit {
	position: absolute;
	top: 0px;
	left: -10px;
	font-size: 36px;
	color: rgb(105, 105, 105);
	cursor: pointer;
	border-radius: 50%;
	padding: 4px;

	&:hover {
		opacity: 0.6;
	}
	@media screen and (min-width: 768px) {
		left: auto;
		top: 15px;
		right: 15px;
	}
}

.decommission-btn {
	@extend .asset-profile-edit;
	top: 0px;
	// background-color: rgb(37, 2, 69);
	// color: white;
	// border: none;
	// padding: 10px;
	// text-align: center;
	// text-decoration: none;
	// display: inline-block;
	// font-size: 16px;
	// margin: 4px 2px;
	// cursor: pointer;
	// border-radius: 8px;
	// transition: background-color 0.3s ease;
	left: auto;
	right: -10px;
	@media screen and (min-width: 768px) {
		right: 50px;
		top: 14px;
	}
}

// .decommission-btn:hover {
// 	background-color: rgb(57, 22, 89);
// }

.confirm-decommission {
	background-color: rgb(37, 2, 69);
}

.confirm-decommission:hover {
    background-color: rgb(57, 22, 89);
}


.asset-edit-modal {
	.mb-3 {
		margin-bottom: 1rem !important;
	}
}

.toggle-img-btn {
	background-color: transparent;
	color: rgb(107, 107, 107);
	width: 40px;
	height: 40px;
	border: 1px solid rgb(195, 195, 195);
	margin: 10px;
	border-radius: 10px;
}

.asset-profile-tag {
	background-color: #228b22;
	border-radius: 100px;
	width: fit-content;
	height: 30px;
	font-size: 12px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	box-shadow: 0 0 5px 1px #5b2286;
}

.asset-profile-tag-wrapper {
	margin-right: 6px;
	display: flex;
	align-items: center;
}

.asset-health-score {
	font-size: 16px;
	background-color: #228b22;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	box-shadow: 0 0 5px 1px #5b2286;
	color: white;
	width: 56px;
	transition: all 300ms;
	height: 30px;
}

.asset-health-row {
	transition: all 300ms;
	cursor: default;
	&:hover .asset-health-score {
		width: 50%;
		height: 10px;
	}
	&:hover {
		padding-top: 20px;
	}
}

.asset-health-red {
	background-color: #df3053;
	box-shadow: 0px 0px 9px 1px #df3053 !important;
}
.asset-health-yellow {
	background-color: #edb649;
	box-shadow: 0px 0px 9px 1px #edb649 !important;
}
.asset-health-green {
	background-color: #228b22;
}

@media screen and (min-width: 768px) {
	.asset-header {
		margin-left: auto;
		margin-right: auto;
		width: 50%;
	}
}
.asset-profile-location-link {
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}
}

.asset-toast {
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
    margin-bottom: 5em;
	top: auto;
	width: fit-content;
	background-color: rgba(0, 0, 0, 0.1);
}
