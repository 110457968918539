.chatbot-main-card {
	height: 100%;
	max-height: 410px;
	width: 100%;

	& > div {
		height: 100% !important;
	}

	& > div > div {
		height: 100% !important;
	}

	.chatbot-avatar {
		margin-top: auto;
	}

	.react-chatbot-kit-chat-bot-message-arrow,
	.react-chatbot-kit-user-chat-message-arrow {
		display: none;
	}

	.react-chatbot-kit-chat-container {
		width: 100% !important;
		height: 100% !important;
	}
	.react-chatbot-kit-chat-inner-container {
		height: 100% !important;
	}
	.react-chatbot-kit-chat-bot-message {
		margin-left: 14px;
		min-width: 50%;
		white-space: pre-wrap;
	}
	.react-chatbot-kit-chat-bot-message,
	.react-chatbot-kit-user-chat-message {
		font-size: 1rem;
		font-weight: 600;
		max-width: 70% !important;
		color: black;
		box-shadow: 0px 8px 16px -9px grey;
		border-radius: 20px;
		padding: 16px 12px;
	}
	.react-chatbot-kit-chat-message-container {
		max-height: calc(100% - 105px) !important;
	}
}

// @media (max-width:1200px) {
// 	.chatbot-main-card {
// 		margin-bottom: 200px;
// 	}
// }
