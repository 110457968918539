.btn-purple {
	color: #fff !important;
	background-color: #250245 !important;
	border-color: #250245 !important;

	&:active,
	&:hover,
	&:focus-visible {
		color: #fff !important;
		background-color: #250245 !important;
		border-color: #250245 !important;
	}
}

.submissions-table {
	scrollbar-width: thin;
	scrollbar-color: #4a4a4a #ddd;

	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		background: #ddd;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ddd;
		border-radius: 6px;
		border: 3px solid #4a4a4a;
		border-bottom-width: 100px;
	}
	.dark-mode & {
		scrollbar-width: thin;
		scrollbar-color: #4a4a4a #ddd;

		&::-webkit-scrollbar-track {
			background: #ddd;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #ddd;
			border-color: #4a4a4a;
		}
	}
}

.asset-types-table {
	table-layout: fixed;
}
