.submissions-table {
	border-radius: 12px;
	max-height: 80vh;

	th {
		font-weight: bold;
	}
	td,
	th {
		font-size: 15px;
	}
	tr {
		cursor: pointer;
	}
	.title {
		font-weight: bold;
	}
}

.submissions-table.no-border {
	border-width: 0px;
}

.submissions-table > .card-header {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.submissions-table > .card,
.submissions-table > #submission {
	overflow: auto;
}

.spinner {
	width: 64px;
	height: 64px;
	border: 8px solid;
	border-color: #3d5af1 transparent #3d5af1 transparent;
	border-radius: 50%;
	animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.is-bold-true,
.is-bold-false {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.edit-log {
	background-color: rgb(240, 240, 240);
	padding: 16px;
	border-radius: 10px;
	width: 100%;

	* {
		background-color: rgb(240, 240, 240);
	}
}

.edit-log-table {
	table-layout: fixed;
}

.form-control:disabled {
	background-color: #e8e8e8 !important;
	opacity: 0.6;
}

.date-range-input {
	input {
		width: 100%;
		padding: 5px 6px;
		border: 1px solid #bebebe;
		border-radius: 5px;
	}
	input:focus {
		outline: 2px solid #0d6efd !important;
	}
}
