.react-datepicker__close-icon{
    background-color: transparent;
}
.react-datepicker__close-icon::after {
    background-color: darkgray !important;
}

.dateInput {
    width: 100%;
	border: none;
	padding: 4px 0 4px 15px;
	background-color: #f4f4f4;
	outline: none;
}

.assetstablecounter {
	width: 100%;
	text-align: right;
}

.assetstablecounter_span{
	padding: 0.5rem;
}

.assetdropdown {
	height: 32px;
	border-radius: 16px;
	padding: 0 10px;
	font-size: 16px;
	margin-top: 1em;
	@media screen and (min-width: 768px) {
		/* width: 300px; */
		margin-top: 0em;
		margin-left: 14px;
	}
}