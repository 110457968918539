.text-gray {
  color: #a6a0ae;
}

.main-card-wrapper {
  border-radius: 20px;
  overflow: hidden;
  height: 340px;
}

.main-card-footer-text-wrapper {
  transition: opacity 1s 1000ms;
}

.main-card-left {
  align-items: flex-start;
}

.dash-overview-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .form-select {
    margin: 10px;
  }
}

.main-card-footer-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;

  & > * {
    cursor: pointer;
  }
}

.footer-icon-wrapper {
  border-radius: 50%;
  background-color: #250245;
  color: white;
  min-width: 66px !important;
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms;
  animation: growBounce 1200ms;
  animation-timing-function: ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
  .main-card-footer-icon {
    font-size: 32px;
  }
}

.main-card-btn {
  color: white !important;
  border: none !important;
  transition: all 100ms ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  animation: growBounce 1200ms;
  animation-timing-function: ease-in-out;

  &:hover {
    transform: scale(1.03);
  }
}

.bg-primary {
  background: rgba(39, 174, 95, 255) !important;
}

.slide-down {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}

.slide-down-hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-30%);
}

@media (max-width: 769px) {
  .main-card-wrapper {
    height: unset;
  }
}
