.loading-spinner,
.loading-spinner-small {
	display: flex;
	margin-top: 0;
	margin-bottom: 0;
	flex-direction: row;
	justify-content: center;
}

.loading-spinner {
	padding-top: 150px;
	padding-bottom: 150px;
}

.loading-spinner-small {
	align-items: center;
	height: 100%;
}

.dark-mode .loading-spinner {
	height: 100vh;
	background-color: $default-body-bg-dark;
}
